import React from 'react';
import PropTypes from 'prop-types';
import Logo from './../../Elements/Logo';
import Footer from '../../Fields/Footer/Footer';
// import Footer from '../../Fields/Footer/Footer';

const Layout = ({
  style,
  children,
  imageURL,
  imageSize,
  imagePosition,
  logo,
}) => (
  <div
    className={style}
    style={{
      backgroundImage: `url(${imageURL})`,
      backgroundSize: `${imageSize || '400'}px auto`,
      backgroundPosition: `${imagePosition || 85}% bottom`,
      backgroundRepeat: 'no-repeat',
    }}
  >
    {logo !== false && (
      <div className="logo">
        <Logo
          width="250px"
          height="40px"
          background="#FFFFFF"
          color="#242424"
          viewBox="10 0 199 40"
        />
      </div>
    )}

    {children}
  </div>
);

Layout.propTypes = {
  style: PropTypes.string,
  children: PropTypes.node,
  imageURL: PropTypes.string,
  imageSize: PropTypes.string,
};

export default Layout;
