import React from 'react';
import PropTypes from 'prop-types';

const Row = ({ children, alignH, alignV }) => (
  <div className="row">
    {children || null}
    <style jsx="true">
      {`
        .row {
          width: 100%;
          display: flex;
          justify-content: ${alignH || 'center'};
          align-items: ${alignV || 'center'};
          flex-wrap: wrap;
        }
      `}
    </style>
  </div>
);

Row.propTypes = {
  alignV: PropTypes.string,
  alignH: PropTypes.string,
  children: PropTypes.node,
};

export default Row;
