import React from 'react';
import Alert from '@mui/material/Alert';

const AlertToast = ({ alertStatus, errorMessage }) => {
  return (
    <>
      <Alert severity={alertStatus ? 'success' : 'error'}>{errorMessage}</Alert>
    </>
  );
};

export default AlertToast;