import React from 'react';
import PropTypes from 'prop-types';
import DynamicInput from '../../../Fields/DynamicInput/DynamicInput';
import Select from '../../../Fields/Select/Select';
import Subtitles from '../../../Fields/Subtitles/Subtitles';
import List from '../../../Elements/List/List';
import ButtonDynamic from '../../../Fields/Button/Button';
import countries from '../../../../data/countries';

const AddressForm = ({
  handleChange,
  city,
  zip,
  country,
  state,
  phone,
  handleSubmit,
  classVal,
  btnOnclick,
  showCountry,
  loader,
}) => {
  return (
    <form
      className="flexDirectionCol flex md:mx-64  md:px-16 mt-4 register--box"
      onSubmit={handleSubmit}
    >
      <p className="text-xs font-medium w-full reg-label mb-1 text-left">
        COUNTRY *
      </p>

      {/*<ButtonDynamic
        btnType="button"
        btnText="Select One"
        customStyle="bg-white border rounded-full w-full py-2 title-color focus:outline-none mb-5 mx-0"
        clickHandler={btnOnclick}
      />
      {showCountry ? (
        <List
          list={countryList}
          customStyle="flex flex-col text-left list-none mt-16 bg-white fixed"
        />
      ) : null}
      */}

      <select
        name="country"
        id="country"
        value={country}
        disabled
        onChange={handleChange}
        className="form-select placeholder-gray-500 placeholder-opacity-100 border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5 mx-0"
      >
        {countries.map((country) => {
          return <option value={country.Code}>{country.Name}</option>;
        })}
      </select>
      <p className="text-xs font-medium w-full reg-label mb-1 text-left">
        STATE *
      </p>
      <DynamicInput
        type="text"
        placeholder=""
        handleChange={handleChange}
        value={state || ''}
        name="state"
        customStyle={classVal}
      />
      <div className="w-full flex md:flex-row	 flex-col	">
        <div className="md:w-3/5 w-full ">
          <p className="text-xs font-medium w-1/2 reg-label mb-1 text-left">
            CITY *
          </p>
          <DynamicInput
            type="text"
            placeholder=""
            handleChange={handleChange}
            value={city || ''}
            name="city"
            customStyle={classVal}
          />
        </div>
        <div className="md:w-3/5 md:ml-4 w-full">
          <p className="text-xs font-medium w-full reg-label mb-1 text-left">
            POSTAL CODE *
          </p>
          <DynamicInput
            type="text"
            placeholder=""
            handleChange={handleChange}
            value={zip || ''}
            name="zip"
            customStyle={classVal}
          />
        </div>
      </div>

      {/* <p className="text-xs font-bold w-full reg-label mb-1 text-left">
        STREET
      </p>
      <DynamicInput
        type="text"
        placeholder=""
        handleChange={handleChange}
        value={street || ""}
        name="street"
        customStyle={classVal}
      /> */}
      <p className="text-xs font-medium w-full reg-label mb-1 text-left">
        PHONE NUMBER *
      </p>
      <DynamicInput
        type="text"
        placeholder=""
        handleChange={handleChange}
        value={phone || ''}
        name="phone"
        customStyle={classVal}
      />

      <button
        className="btn-fl hover:btn-hv text-white font-medium py-2 p-24 rounded-full"
        type="submit"
        disabled={!phone || !state || !city || !zip}
      >
        Get Started
      </button>
      <div className="flex py-4">
        <Subtitles
          customStyle="text-xs pl-4 sub-color"
          value="or press ENTER↵"
        />
      </div>
    </form>
  );
};

AddressForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  classVal: PropTypes.string,
  imageName: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  qualification: PropTypes.string,
  month: PropTypes.string,
  day: PropTypes.string,
  year: PropTypes.string,
  loader: PropTypes.bool,
};

export default AddressForm;
