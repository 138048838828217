import React, { useContext } from 'react';
import logo from '../../../assets/images/logo.svg';
import AuthContext from './../../../context/auth/authContext';

const AppHeader = ({ position, history, context, path, home, course }) => {
  const authContext = useContext(AuthContext);
  const { logOut } = authContext;

  const handleLogOut = (e) => {
    e.preventDefault();
    history.push('/');
    logOut();
  };

  return (
    <header
      className={` p-5  sticky top-0 w-full z-10 ${
        position > 5 ? 'shadow-md bg-white' : ''
      }`}
    >
      <div className="flex  items-center md:justify-between justify-center flex-col	md:flex-row	 m-auto  max-w-6xl">
        <div className="md:h-1/6 md:w-1/6  ">
          <img src={logo} />
        </div>
        <div className="mt-4 md:mt-0">
          {course && (
            <button
              onClick={() => history.push('/course/details')}
              className="border  border-gray-500 md:mr-5 mr-2 px-5 py-1 rounded-full md:text-sm text-xs uppercase"
            >
              Course
            </button>
          )}
          {home && (
            <button
              onClick={() => history.push('/')}
              className="border  border-gray-500 md:mr-5 mr-2 px-5 py-1 rounded-full md:text-sm text-xs uppercase"
            >
              Home
            </button>
          )}
          <button
            onClick={() => history.push(path)}
            className="border  border-gray-500 md:mr-5 mr-2 px-5 py-1 rounded-full md:text-sm text-xs uppercase"
          >
            {context}
          </button>
          {localStorage.getItem('token') ? (
            <button
              onClick={handleLogOut}
              className=" px-5 py-1 bg-gray-800 hover:btn-hv text-white  rounded-full  md:text-sm text-xs uppercase outline-none"
            >
              Logout
            </button>
          ) : (
            <>
            <button
              onClick={() => history.push('/Login')}
              className=" px-5 py-1 bg-gray-800 hover:btn-hv text-white  rounded-full  md:text-sm text-xs uppercase outline-none"
            >
              Login
            </button>
            <button
                onClick={() => history.push('/Register')}
                className="ml-2 px-5 py-1 bg-gray-800 hover:btn-hv text-white rounded-full md:text-sm text-xs uppercase outline-none"
              >
                Signup
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
