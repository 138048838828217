import React from 'react';
import PropTypes from 'prop-types';

const ButtonDynamic = ({
    customStyle,
    clickHandler,
    btnType,
    btnText,
  }) => (
    <button 
    className={customStyle} 
    onClick={clickHandler} 
    type={btnType}>{btnText}</button>
  );
  
  ButtonDynamic.propTypes = {
    customStyle: PropTypes.string,
    btnType: PropTypes.string,
    clickHandler: PropTypes.func,
    btnText: PropTypes.string,
  };
  export default ButtonDynamic;
  