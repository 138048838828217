import React, { useState, useEffect, useRef, useContext } from 'react';
import { CoursesList } from './../../data/courses';
import AppHeader from '../../components/Elements/Header/Header';
import aboutBanner from '../../assets/images/personal_info.png';
import AuthContext from '../../context/auth/authContext';

const CourseDetails = ({ history }) => {
  const authContext = useContext(AuthContext);
  const [position, setPoisition] = useState(0);
  const [goingUp, setGoingUp] = useState(false);
  const prevScrollY = useRef(0);
  const { fetchUser, user, enrollCourse } = authContext;
  const [api, setApi] = useState(false);
  const [enroll, setEnroll] = useState(false);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      if (user.enrollment.length) {
        const status = user.enrollment.filter((e) => e.status === 'active');
        if (status.length) {
          setEnroll(true);
        }
      }
      setApi(true);
    }
  }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      setPoisition(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [goingUp]);

  const enrollCustomer = (data) => {
    enrollCourse(data.id);
    history.push('/course/' + data.id + '/payment');
  };

  return (
    <div className="w-full h-full">
      <AppHeader
        history={history}
        course={false}
        home={true}
        position={position}
        context="Support & FAQ"
        path="/support"
      />
      <div className="m-auto max-w-6xl mt-5 md:px-0 px-4 ">
        {user && user !== null && (
          <section className="flex md:mt-28 mt-14 flex-col items-start mb-6">
            <h4 className="md:text-3xl text-2xl font-semibold">My Courses</h4>
            <p className="text-1xl  text-center">
              Courses you’ve worked on recently
            </p>
            <div className="flex flex-wrap max-w-6xl w-full  mt-4">
              <div
                className="course-card md:max-w-1/3 max-w-full md:mt-0 mt-2 relative border border-gray-200 cursor-pointer mr-2 rounded shadow-lm w-full"
                style={{
                  minHeight: 250,
                }}
              >
                <div
                  className="p-5"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <h1 className="font-semibold text-white my-3 text-center">
                    Certified Security Guard (CSG)
                  </h1>
                  <button
                    style={{ background: 'rgb(68, 167, 81)' }}
                    onClick={() => history.push('/course/details/view')}
                    className="mt-4 text-center px-5 py-1 hover:btn-hv text-white mr-5 rounded-full text-sm uppercase outline-none"
                  >
                    Go to course
                  </button>
                </div>
              </div>
            </div>
          </section>
        )}
        <section className="flex md:mt-28 mt-14 flex-col items-start mb-6">
          <h4 className="md:text-3xl text-2xl font-semibold">
            Popular Courses
          </h4>
          <div className="flex flex-wrap max-w-6xl w-full  mt-4">
            {CoursesList.map((items, index) => {
              if (index === 0) {
                if (user === null || !user?.enrollment.length) {
                  return (
                    <CourseCard
                      history={history}
                      index={index}
                      items={items}
                      enrollCustomer={() =>
                        history.push('/course/details/view')
                      }
                    />
                  );
                }
              } else {
                return (
                  <CourseCard
                    history={history}
                    index={index}
                    items={items}
                    enrollCustomer={() => console.log('pending')}
                  />
                );
              }
            })}
          </div>
        </section>

        <div className="footer bg-transparent py-6">
          <div className="container mx-auto flex items-center justify-center flex-col px-4">
            <div>
              <p class="text-center text-xs leading-6 text-gray-400">
                © 2021 International Security & Safety Academy. All rights
                reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function CourseCard({ items, enrollCustomer, index }) {
  return (
    <div
      className="course-card md:max-w-1/3 max-w-full md:mt-0 mt-2 relative border border-gray-200 cursor-pointer mr-2 rounded shadow-lm w-full"
      style={{
        // maxWidth: '32%',
        minHeight: 250,
      }}
    >
      <div
        className="p-5"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h1 className="font-semibold text-white my-3 text-center">
          {items.name}
        </h1>
        {/* <p className="leading-6 text-left  text-xs">
          Our online micro learning model offers numerous advantages compared to
          traditional, often too long boring lessons. It is more convenient,
          improves content retention & self paced learning. Our methodology of
          learning is f
        </p> */}
        <button
          onClick={() => enrollCustomer()}
          className="btn-fl text-sm bg-gray-800 text-white font-bold py-2 p-8 rounded-full"
          type="submit"
        >
          {index === 0 ? 'Go to course' : 'Coming Soon'}
        </button>
      </div>
    </div>
  );
}

export default CourseDetails;
