import React from 'react';
import { Box, Button, Grid, makeStyles, TextField, Typography } from '@mui/material';
import './ContactUs.scss';
import Navbar from '../../components/Elements/Navbar/Navbar';
import Footer from '../../components/Elements/Footer/Footer';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';


function ContactUs({ history }) {
    return (
        <Box className='contact__us'>
            <Navbar history={history} course={true} faq={true} />
            <Grid container xs={12} className='container__grid'>
                <Grid container xs={12} md={4} item className='info__grid'>
                    <Grid item xs={12}>
                        {/* <Grid item xs={12} className='grid__item'>
                            <div className='icon__box'>
                                <CallIcon className='contact__icon'/>
                                <Typography variant='h6' fontWeight={700} fontSize={24}>
                                    Call us
                                </Typography>
                            </div>
                            <div className='txt__box'>
                                <Typography variant='h6' fontSize={16}>
                                    (480) 555-0103
                                </Typography>
                            </div>
                        </Grid> */}
                        <Grid item xs={12} className='grid__item'>
                            <div className='icon__box'>
                                <LocationOnIcon className='contact__icon' />
                                <Typography variant='h6' fontWeight={700} fontSize={24}>
                                    Location
                                </Typography>
                            </div>
                            <div className='txt__box'>
                                <Typography variant='h6' fontSize={16}>
                                    London, United Kingdom
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} className='grid__item'>
                            <div className='icon__box'>
                                <LanguageIcon className='contact__icon'/>
                                <Typography variant='h6' fontWeight={700} fontSize={24}>
                                    Mail us
                                </Typography>
                            </div>
                            <div className='txt__box'>
                                <Typography variant='h6' fontSize={16}>
                                    support@issacademy.com
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6} container className='contact__now__grid'>
                    <Grid item>
                        <Typography variant='h3' className='title__txt'>
                            Contact Now
                        </Typography>
                        <Typography variant='body2' className='body__txt'>
                            Revolutionize your security career with ISSA's online training. Join the experts today and accelerate your know-how. Contact us now to take the first step towards success.
                        </Typography>
                    </Grid>
                    <div className='text__field__box'>
                        <TextField className='text__field' id="standard-basic" label="Name" variant="standard" required />
                        <TextField className='text__field' id="standard-basic" label="Email" variant="standard" required />
                        <TextField className='text__field' id="standard-basic" label="Phone" variant="standard" required />
                        <TextField className='text__field' id="standard-basic" label="Message" variant="standard" />
                    </div>
                    <Grid item>
                        <Button size='small' variant='contained' className='submit__btn'>Submit Form</Button>
                    </Grid>
                </Grid>

            </Grid>
            {/* <Footer /> */}
        </Box>
    )
}

export default ContactUs;
