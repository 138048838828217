import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
// import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Elements/Navbar/Navbar";
import './Faq.scss';
import Footer from '../../components/Elements/Footer/Footer';

function Faq({ history }) {
    return (
        <Box className='faq__box'>
            <Navbar history={history} color="#071E30" course={true} faq={true}
            />
            <Grid container xs={12} className='container__grid'>
                <Grid xs={10} sm={10} md={8} lg={6} item className='item__grid'>
                    <Typography variant='h3' className='title__txt'>
                        Frequently Asked Questions
                    </Typography>
                    <Typography variant='body2' className='body__txt'>
                        Here are some frequently asked questions, we’re pretty sure we’ve got your curious mind answered.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container xs={12} className='questions__container'>
                <Grid item xs={10} sm={10} md={9} lg={8} className='item__grid'>
                    <Typography variant='h6' className='question__txt'>
                        How are ISSAcademy courses different from conventional security courses?
                    </Typography>
                    <Typography variant='body2' className='answer__txt'>
                        Most of the courses needs huge investment of money & time. Most of the courses are taught by people who lack real world experiences and are outdated in content & curriculum. Our micro-learning approach of learning is more convenient, improves content retention & self paced learning.
                    </Typography>
                </Grid>

                <Grid item xs={10} sm={10} md={9} lg={8} className='item__grid'>
                    <Typography variant='h6' className='question__txt'>
                        Do I need previous knowledge in any security field?
                    </Typography>
                    <Typography variant='body2' className='answer__txt'>
                        No. All our students are from almost every background & industry, who are looking for a career change in the field of security industry.
                    </Typography>
                </Grid>

                <Grid item xs={10} sm={10} md={9} lg={8} className='item__grid'>
                    <Typography variant='h6' className='question__txt'>
                        Do you require any certain pre-qualification?
                    </Typography>
                    <Typography variant='body2' className='answer__txt'>
                        It depends on courses enrolled.
                    </Typography>
                </Grid>

                <Grid item xs={10} sm={10} md={9} lg={8} className='item__grid'>
                    <Typography variant='h6' className='question__txt'>
                        Does ISSAcademy support students to clear their doubts & questions on the certification enrolled?
                    </Typography>
                    <Typography variant='body2' className='answer__txt'>
                        The student can post their questions via email at student@issacademy.com. ISSAcademy will soon lunch an expert forum via which questions can be   posted to our security experts.
                    </Typography>
                </Grid>

                <Grid item xs={10} sm={10} md={9} lg={8} className='item__grid'>
                    <Typography variant='h6' className='question__txt'>
                        Are there any tests/exams upon course completion for certification?
                    </Typography>
                    <Typography variant='body2' className='answer__txt'>
                        Yes. Upon successful completion of the course, there will be an exam where you must pass all tests to receive ISSAcademy certification.
                    </Typography>
                </Grid>

                <Grid item xs={10} sm={10} md={9} lg={8} className='item__grid'>
                    <Typography variant='h6' className='question__txt'>
                        What will be the life span of each program?
                    </Typography>
                    <Typography variant='body2' className='answer__txt'>
                        It depends on the course enrolled.
                    </Typography>
                </Grid>
            </Grid>
            <Footer />
        </Box>
    )
}

export default Faq;