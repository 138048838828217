import React, { useState, useEffect, useContext } from 'react';

import { withRouter } from 'react-router-dom';

import address_image from '../../assets/images/address.png';
import AuthContext from '../../context/auth/authContext';
import Navbar from '../../components/Fields/Navbar/Navbar';
import Layout from '../../components/Elements/Layout/Layout';
import Footer from '../../components/Fields/Footer/Footer';

export const Confirm = ({ dashboard, history }) => {
  const authContext = useContext(AuthContext);
  const { fetchVendor, paymentRequest } = authContext;

  useEffect(() => {
    paymentRequest();
  }, []);

  return (
    <div className="w-full">
      <Layout
        style="register flex dash-bgcolor w-full h-full fixed"
        imageURL={address_image}
        imageSize="250"
        imagePosition="90"
      >
        <div className="flex w-full  px-8">
          <div className="flex-col w-full flex justify-center text-center items-center">
            <h4 className="md:text-2xl text-xl">
              Congratulations!. Your account has been registered successfully !
            </h4>
            {/* <p className="md:w-1/2 2/2 md:text-xs text-sm">A confirmation email with details of payments & procedures is sent to your registered email.</p> */}
            <button
              className="border mt-4 border-gray-500 px-5 py-1 rounded-full text-sm uppercase"
              type="submit"
              onClick={() => history.replace('/')}
            >
              Home
            </button>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default withRouter(Confirm);
