import React, { useState, useContext, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import Layout from '../../../components/Elements/Layout/Layout';
import Sidebar from '../../../components/Fields/Sidebar/Sidebar';
import Title from '../../../components/Fields/Title/Title';
import Subtitles from '../../../components/Fields/Subtitles/Subtitles';
import AddressForm from '../../../components/Forms/RegisterForm/Address/AddressForm';
import AuthContext from '../../../context/auth/authContext';
import phoneCode from './../../../data/PhoneCode';
import address_image from '../../../assets/images/address.png';

import { listObject } from '../../../components/Constant';
import Footer from '../../../components/Fields/Footer/Footer';

export const Address = ({ address, history }) => {
  const authContext = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const { addressSet, setAddress, message } = authContext;
  const email = localStorage.getItem('email');

  const [data, setData] = useState({
    listElem: 2,
    country: localStorage.getItem('country'),
    city: '',
    zip: '',
    street: '',
    state: '',
    phone: '',
    showCountry: false,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
     const phoneDigits = phone.replace(/[^0-9]/g, ''); 
    if (
      city === '' ||
      zip === '' ||
      state === '' ||
      phone === ''
    ) {
      alert('Please fill the fields');
      setLoader(false);
    } else if (phoneDigits.length < 9 || phoneDigits.length > 15) {
      alert('Phone number should be between 9 and 15 digits.');
      setLoader(false);
    }  else {
      setAddress({
        phone,
        address: { country, city, zip, street, state },
      });
    }
  };

  useEffect(() => {
    const number = phoneCode.filter(e => e.code === localStorage.getItem("country"));
    setData({...data, phone: number[0].dial_code});
  }, [])

  useEffect(() => {
    if (addressSet) {
      history.push('/Legal');
    }
  }, [addressSet, history]);

  useEffect(() => {
    if (message !== null) {
      setLoader(false);
      alert(message);
    }
  }, [message]);

  const toggleCountry = () => {
    const doesShow = data.showCountry;
    setData({ ...data, showCountry: !doesShow });
  };

  const {
    listElem,
    country,
    city,
    zip,
    street,
    state,
    phone,
    showCountry,
  } = data;

  return (
    <Layout
      style="register flex dash-bgcolor w-full h-full fixed"
      imageURL={address_image}
      imageSize="250"
      imagePosition="90"
    >
      <div className="flex w-full h-full">
        <Sidebar listActive={listElem} liObject={listObject} />
        <div className="flex-col w-full h-full md:mr-32 p-8 md:p-0">
          <div className="text-center pt-12">
            <Title
              customStyle="md:text-4xl text-xl font-medium p-4 title-color"
              value="Please enter your address"
            />
            <Subtitles
              customStyle="text-xs p-4 pt-2 sub-color"
              value="Kindly enter your address information to proceed with the registration process."
            />
            <AddressForm
              handleChange={handleInput}
              handleSubmit={handleSubmit}
              country={country}
              city={city}
              zip={zip}
              street={street}
              state={state}
              phone={phone}
              btnOnclick={toggleCountry}
              showCountry={showCountry}
              loader={loader}
            />
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(Address);
