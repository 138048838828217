import React, { useEffect, useContext } from 'react';
import AuthContext from '../../context/auth/authContext';
import Logo from './../../components/Elements/Logo';
import address_image from '../../assets/images/address.png';
import Layout from '../../components/Elements/Layout/Layout';

const Certificate = ({ match, history }) => {
  const authContext = useContext(AuthContext);
  const { certificate, getCertificate, user, fetchUser } = authContext;

  useEffect(() => {
    if (certificate === null) {
      getCertificate(match.params.id);
    }
    fetchUser();
  }, [certificate]);

  return (
    <div className="w-full">
      <Layout
        style="register flex dash-bgcolor w-full h-full fixed"
        imageURL={address_image}
        imageSize="250"
        imagePosition="90"
      >
        <div className="flex w-full bg-gray-200  ">
          <div className="flex-col h-screen w-full flex justify-center text-center items-center">
            <div className="bg-white mt-4 w-5/12	h-9/12 relative rounded-md p-12 text-left">
              <div className="flex justify-center ">
                <Logo />
              </div>
              <h4 className="mt-4 text-md">
                Congratulations! Here is{' '}
                <strong className="font-black	">
                  your digital certificate for Certified Security Guard (CSG)
                </strong>
                .
              </h4>
              <p className="mt-4 text-xs">
                Congratulations on receiving your Certified Security Guard (CSG)
                certificate from Emcode Technology LLC! You can now share or
                download your certificate.
              </p>
              <span className="block mt-4 text-xs">
                To get the most out of your digital certificate, take these next
                steps:
              </span>
              <ul className="mt-4 ml-10 text-xs list-disc font-black">
                <li>View your certificate</li>
                <li>Share it on your LinkedIn feed</li>
                <li>Update your LinkedIn profile</li>
              </ul>
              <p className="mt-4 text-xs">
                Your certificate is available in an online format so that you
                can retrieve it anywhere at any time, and easily share the
                details of your achievement.
              </p>
              <div className="flex justify-center mt-8">
                <button
                  className="border  border-gray-500 px-5 py-1 rounded-full cursor-pointer text-sm uppercase"
                  type="submit"
                  onClick={() =>
                    window.open(certificate.accredibleCertificateUrl)
                  }
                >
                  View My Certificate
                </button>
                <button
                  className="border ml-4  border-gray-500 px-5 py-1 rounded-full cursor-pointer text-sm uppercase"
                  type="submit"
                  onClick={() =>
                    window.open(certificate.accredibleCertificateUrl)
                  }
                >
                  Add to LinkedIn Profile
                </button>
              </div>
              <div className="bg"></div>
            </div>
            <button
              className="border mt-4 border-gray-500 px-5 py-1 rounded-full text-sm uppercase"
              type="submit"
              onClick={() => history.replace('/')}
            >
              Home
            </button>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Certificate;
