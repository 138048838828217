import React, { useState, useEffect, useContext } from 'react';

import { withRouter } from 'react-router-dom';

import address_image from '../../assets/images/address.png';
import AppHeader from '../../components/Elements/Header/Header';
import Layout from '../../components/Elements/Layout/Layout';

export const Confirm = ({ history, match }) => {
  return (
    <div className="w-full h-screen overflow-hidden">
      <Layout
        style="register dash-bgcolor w-full h-full fixed"
        imageURL={address_image}
        imageSize="250"
        imagePosition="90"
        logo={false}
      >
        {/* <AppHeader
          history={history}
          course={false}
          home={true}
          position={null}
          context="Support & FAQ"
          path="/support"
        /> */}
        <div className="flex w-full h-full px-8">
          <div className="flex-col w-full flex justify-center text-center items-center">
            <h4 className="md:text-xl text-xl">
              Congratulations!. Your payment has been completed successfully !
            </h4>
            {/* <p className="md:w-1/2 2/2 md:text-xs text-sm">A confirmation email with details of payments & procedures is sent to your registered email.</p> */}
            <button
              className="border mt-4 border-gray-500 px-5 py-1 rounded-full text-sm uppercase hover:bg-gray-800 hover:text-white"
              type="submit"
              onClick={() => history.push(`/Course/${match.params.id}/lecture`)}
            >
              Go to course
            </button>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default withRouter(Confirm);
