import React from 'react';
import PropTypes from 'prop-types';
import countries from '../../../data/countries';
import DynamicInput from '../../Fields/DynamicInput/DynamicInput';
import Loader from '../../Fields/Loader/Loader';

const RegisterForm = ({
  handleChange,
  email,
  password,
  handleSubmit,
  labelval,
  classVal,
  region,
  formStep,
  passwordlabel,
  loader,
}) => (
  <form className="p-4 flexDirectionCol flex h-full" onSubmit={handleSubmit}>
    <p className="text-xs font-medium w-full reg-label mb-1">{labelval}</p>
    <DynamicInput
      type="text"
      placeholder="email@domain.com"
      handleChange={handleChange}
      value={email || ''}
      name="email"
      customStyle={classVal}
    />
    <p className="text-xs font-medium w-full reg-label mb-1">{passwordlabel}</p>
    <DynamicInput
      type="password"
      placeholder="password"
      handleChange={handleChange}
      value={password || ''}
      name="password"
      customStyle={classVal}
    />
    <p className="text-xs font-medium w-full reg-label mb-1">Country</p>
    <select
      name="region"
      id="region"
      onChange={handleChange}
      className="form-select placeholder-gray-500 placeholder-opacity-100 border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5 mx-0"
    >
      <option value="">Select Country</option>
      {countries.map((country) => {
        return <option value={country.Code}>{country.Name}</option>;
      })}
    </select>
    <button
      className="btn-fl hover:btn-hv text-white font-medium py-2 p-16 rounded-full"
      type="submit"
      disabled={!email || !region}
    >
      {loader ? <Loader /> : 'Get Started'}
    </button>
  </form>
);

RegisterForm.propTypes = {
  handleChange: PropTypes.func,
  email: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  confirmpass: PropTypes.string,
  handleSubmit: PropTypes.func,
  formStep: PropTypes.number,
};

export default RegisterForm;
