import React, { useEffect, useState, useRef, useContext } from 'react';
import courseBanner from '../../assets/images/coursebg.jpg';
import Footer from '../../components/Fields/Footer/Footer';
import AuthContext from './../../context/auth/authContext';
import logo from '../../assets/images/logo.svg';

import { AppBar, Box, Button, Menu, MenuItem, styled, Toolbar, Typography } from "@mui/material";

export default function CourseView({ history }) {
  const prevScrollY = useRef(0);

  const authContext = useContext(AuthContext);
  const { logOut, fetchUser, user, enrollCourse } = authContext;
  const [goingUp, setGoingUp] = useState(true);
  const [position, setPoisition] = useState(0);
  const [api, setApi] = useState(false);
  const [enroll, setEnroll] = useState(false);

  const ButtonBox = styled(Box)({
    display: 'flex'
  })

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      if (user.enrollment.length) {
        const status = user.enrollment.filter((e) => e.status === 'active');
        if (status.length) {
          setEnroll(true);
        }
      }
      setApi(true);
    }
  }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      setPoisition(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [goingUp]);

  const handleLogOut = (e) => {
    e.preventDefault();
    history.push('/');
    logOut();
  };

  const enrollCustomer = (data) => {
    enrollCourse(data.id);
    history.push('/course/' + data.id + '/payment');
  };

  return (
    <div className="w-full">
      <header
        className={` p-5  sticky top-0 w-full z-10 ${position > 5 ? 'shadow-md bg-white' : ''
          }`}
      >
        <div className="flex  items-center md:justify-between justify-center flex-col	md:flex-row	 m-auto  max-w-6xl">
          <div className="md:h-1/6 md:w-1/6  " onClick={() => history.push('/')} style={{cursor: 'pointer'}}>
            <img src={logo} />
          </div>
          <div className="mt-2 md:mt-0">
            {enroll && api && (
              <Button
                onClick={() =>
                  history.push('/Course/60ac8f7e9276872889ed83e3/lecture')
                }
                variant='contained'
                sx={{ borderRadius: '30px', background: '#28d79c', marginRight: '10px', '&:hover': {
                  background: '#23bd89'}, }}
              >
                Go to course
              </Button>
            )}
            {!enroll && api && (
              // <button
              //   style={{ background: 'rgb(68, 167, 81)' }}
              //   onClick={() =>
              //     enrollCustomer({
              //       id: '60ac8f7e9276872889ed83e3',
              //     })
              //   }
              //   className=" text-center px-5 py-1 hover:btn-hv text-white mr-5 rounded-full text-sm uppercase outline-none"
              // >
              //   Enroll
              // </button>

              <Button
                onClick={() =>
                  enrollCustomer({
                    id: '60ac8f7e9276872889ed83e3',
                  })
                }
                variant="contained" 
                sx={{ borderRadius: '30px', background: '#28d79c', marginRight: '10px', '&:hover': {
                  background: '#23bd89'}, }}>
                Enroll
              </Button>
            )}
            {/* <button
              onClick={() => history.push('/')}
              className="border  border-gray-500 md:mr-5 mr-2 px-5 py-1 rounded-full md:text-sm text-xs uppercase"
            >
              Home
            </button> */}
            {/* {localStorage.getItem('token') ? (
              <button
                onClick={handleLogOut}
                className=" px-5 py-1 bg-gray-800 hover:btn-hv text-white  rounded-full  md:text-sm text-xs uppercase outline-none"
              >
                Logout
              </button>
            ) : (
              <button
                onClick={() => history.push('/Login')}
                className=" px-5 py-1 bg-gray-800 hover:btn-hv text-white  rounded-full  md:text-sm text-xs uppercase outline-none"
              >
                Login
              </button>
            )} */}

            {localStorage.getItem('token') ? (
              <Button
                onClick={handleLogOut}
                className="login__btn" variant="contained" sx={{ borderRadius: '30px', background: '#eb445a', '&:hover': {
                  background: '#ed576b !important'} }}>
                Logout
              </Button>
            ) : (
              <Button
                onClick={() => history.push('/Login')}
                className="login__btn" variant="contained" sx={{ borderRadius: '30px', background: '#ed576b', '&:hover': {
                  background: '#ed576b !important'} }}>
                Login
              </Button>
            )}
          </div>
        </div>
      </header>



      <div className="m-auto max-w-6xl md:px-0 px-4" style={{ marginTop: '6pc' }}>
        <section className="mt-16  flex md:flex-row flex-col flex-grow">
          <div className="md:w-3/5 w-full">
            <h1 className="md:text-2xl text-xl mb-2 font-semibold">
              Certified Security Guard (CSG)
            </h1>
            <p className="leading-6 max-w-lg  text-sm">
              At ISSAcademy, Learn the key ideas behind becoming a certified
              Security Guard, with this disruptive online micro-learning course
              – no previous experience required!
            </p>
            <p className="leading-6 max-w-lg my-5 text-sm">
              This course is ideal for any high school pass or above, who wants
              to learn the security guard fundamentals, or an early professional
              who wants to strengthen their knowledge of core security guard
              concepts.
            </p>
            <p className="leading-6 max-w-lg my-5 text-sm">
              Whether you're exploring security industry for the first time or
              looking to deepen your understanding, this course will allow you
              to develop the problem-solving techniques you need to think like a
              security guard.
            </p>
          </div>
          <div className=" md:w-2/5">
            <img src={courseBanner} className="md:m-auto   w-full" />
          </div>
        </section>
        <section className="mt-12 mb-20 flex">
          <div className="w-full">
            <h1 className="md:text-xl text-xl font-semibold">Topics covered</h1>
            <ul className="ml-8 mt-4 topics">
              <li>Roles & Responsibilities</li>
              <li>Customer Care & Social Skills</li>
              <li>Fire Awareness</li>
              <li>Health and Safety at Workplace</li>
              <li>Access Control</li>
              <li>Patrolling</li>
              <li>Searching</li>
              <li>Communication & Reporting</li>
              <li>Security & Emergency System</li>
              <li>Emergencies</li>
              <li>Equality & Diversity</li>
              <li>Communication & Conflict Management</li>
              <li>Evidence and Crime Scenes Preservation</li>
            </ul>
            <span className="block mt-8">No previous knowledge required!</span>
            <span>Certification eligibility: High school (Pass) or above</span>
          </div>
        </section>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
