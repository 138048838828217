import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import ExamTimer from '../../components/Fields/Timer/Timer';
import AuthContext from '../../context/auth/authContext';
import ProgressBar from '../../components/Fields/ProgressBar/Progressbar';

const ExamQuestion = ({ match, history }) => {
  const [examDetails, setExamDetails] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const authContext = useContext(AuthContext);
  const [duration, setDuration] = useState(0);
  const [attemptId, setAttemptId] = useState('');
  const [percentage, setPercentage] = useState(0);
  const {
    fetchExams,
    enrollExam,
    answerQuestion,
    endExam,
    examList,
    questionList,
    startTime,
  } = authContext;

  useEffect(() => {
    enrollExam(match.params.id);
    fetchExams(match.params.course);
    // fecthQuestions(match.params.id);
  }, []);

  useEffect(() => {
    if (examList.length) {
      const data = examList[0].exams[0];
      setExamDetails(data);
    }
  }, [examList]);
  useEffect(() => {
    if (questionList.length) {
      setQuestions(questionList);
      const location = localStorage.getItem('location');
      const split = location.split('/');
      setAttemptId(split[5]);
    }
  }, [questionList]);

  useEffect(() => {
    const currentTime = moment();
    const slot = localStorage.getItem('slot');
    const examStartTime = moment.utc(slot).local();
    const examDuration = moment.duration(examDetails.duration, moment.ISO_8601);
    const examEndTime = moment(examStartTime).add(examDuration, 'seconds');
    const currentDuration = moment.duration(examEndTime.diff(currentTime));
    if (
      parseInt(currentDuration.asSeconds()) <= examDuration.asSeconds() &&
      parseInt(currentDuration.asSeconds()) >= 1
    ) {
      setDuration(parseInt(currentDuration.asSeconds()));
    }
  }, [startTime]);

  const checkedOption = (e, index) => {
    const status = e.target.value;
    let questionArray = [...questions];
    if (status) {
      questionArray[selectedIndex].checked =
        questionArray[selectedIndex].options[index];
    } else {
      questionArray[selectedIndex].checked = '';
    }
    setQuestions(questionArray);
    answerQuestion(attemptId, {
      question: questionArray[selectedIndex]._id,
      answer: index,
    });
  };

  const submitExam = () => {
    endExam(attemptId, history);
  };

  useEffect(() => {
    getPercentage();
  }, [questions]);

  const getPercentage = () => {
    const data = questions.filter((e) => e.checked && e.checked !== '');
    const percentage = (data.length * 100) / questions.length;
    setPercentage(percentage);
  };
  return (
    <div className="bg-white w-full flex flex-col min-h-screen h-screen relative">
      <div className="flex w-full h-full flex-1 items-stretch">
        <div className="w-2/5 h-full px-12 py-8 flex-initial overflow-y-auto border-r-1">
          <a className="flex justify-between">
            <h4 className="text-2xl">{examDetails?.name}</h4>
          </a>
          <span className="mt-8 bg-gray-100 h-0.5 block w-full">&nbsp;</span>
          <ul>
            {questions.map((e, index) => (
              <li
                className={`flex items-start mt-4 cursor-pointer p-2 ${
                  selectedIndex === index && 'bg-gray-100 rounded-md'
                }`}
                onClick={() => {
                  if (selectedIndex >= index) {
                    setSelectedIndex(index);
                  }
                }}
              >
                <span
                // className={`w-1/12 ${e.checked ? 'exam_tick' : 'exam_lock'}`}
                >
                  &nbsp;
                </span>
                <a className="w-11/12 flex">
                  <span>
                    {' '}
                    {e.checked ? (
                      <i class="fa fa-check-circle mr-2"></i>
                    ) : (
                      <i class="fa fa-times-circle mr-2"></i>
                    )}
                  </span>
                  <h4 className="text-sm ml-4 text-gray-600">{e.title}</h4>
                </a>
              </li>
            ))}
          </ul>
        </div>
        {questions.length && (
          <div className="w-3/5 h-full bg-gray-100 relative">
            <div className="px-24 py-24">
              <h4 className="text-lg">{questions[selectedIndex].title}</h4>
              <div className="mt-8">
                <div class="grid grid-cols-2">
                  {questions[selectedIndex].options.map((e, index) => (
                    <div
                      className={`flex p-8 border-solid border-gray-300 border ${
                        e === questions[selectedIndex].checked && 'card_checked'
                      }`}
                    >
                      <span className="mr-4">
                        <input
                          type="checkbox"
                          checked={e === questions[selectedIndex].checked}
                          onChange={(x) => checkedOption(x, index)}
                          className="form-checkbox mt-2 h-4 w-4 transition duration-150 ease-in-out"
                        />
                      </span>
                      <p className=" text-xs mt-2 leading-6">{e}</p>
                    </div>
                  ))}
                </div>

                {/*  */}
              </div>
              <div
                className={`flex ${
                  selectedIndex !== 0 ? 'justify-between' : 'justify-end'
                }`}
              >
                {selectedIndex !== 0 && (
                  <button
                    className="btn-fl hover:btn-hv text-white font-bold py-1 p-8 mt-8 rounded-full"
                    type="submit"
                    onClick={() => setSelectedIndex(selectedIndex - 1)}
                  >
                    Previous
                  </button>
                )}
                {selectedIndex + 1 === questions.length ? (
                  <button
                    style={{ background: 'rgb(68, 167, 81)' }}
                    className="hover:btn-hv text-white font-bold py-2 p-8 mt-8 rounded-full"
                    type="submit"
                    onClick={submitExam}
                  >
                    Submit Exam
                  </button>
                ) : (
                  <button
                    className="btn-fl hover:btn-hv text-white font-bold py-1 p-8 mt-8 rounded-full"
                    type="submit"
                    onClick={() => setSelectedIndex(selectedIndex + 1)}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            <div className="flex exam-bar mt-8">
              <div className="flex flex-1 items-center">
                <ProgressBar bgcolor="#02bb87" completed={percentage} />
                <p className="text-sm text-gray-400 ml-4">
                  {selectedIndex + 1} / {questions.length} LEFT
                </p>
              </div>
              <div>
                <ExamTimer
                  callBack={(e) => {
                    console.log(e);
                    if (e <= 1) {
                      submitExam();
                    }
                  }}
                  text="Remaining:"
                  totalHours={duration}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExamQuestion;
