import React, { useState, useContext, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import Layout from '../../../components/Elements/Layout/Layout';
import Sidebar from '../../../components/Fields/Sidebar/Sidebar';
import Title from '../../../components/Fields/Title/Title';
import Subtitles from '../../../components/Fields/Subtitles/Subtitles';
import PersonalForm from '../../../components/Forms/RegisterForm/PersonalInfo/PersonalInfoForm';
import AuthContext from '../../../context/auth/authContext';

import personal_info from '../../../assets/images/personal_info.png';

import { listObject } from '../../../components/Constant';
import Footer from '../../../components/Fields/Footer/Footer';

export const PersonalInfo = ({ personal, history }) => {
  const authContext = useContext(AuthContext);
  const { updated, message, updateRegister } = authContext;
  const [state, setState] = useState({
    listElem: 1,
    firstName: '',
    lastName: '',
    qualification: '',
    month: '',
    day: '',
    year: '',
    showQuali: false,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const dob = year + '-' + month + '-' + day;
    // if (firstName === '' || lastName === '' || dob === '--') {
    //   alert('Please fill the fields');
    // } else {
    //   updateRegister({ firstName, lastName, dob, qualification });
    // }

    const dob = new Date(`${year}-${month}-${day}`);
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
    const dayDifference = today.getDate() - dob.getDate();

    if (
      firstName === '' ||
      lastName === '' ||
      month === '' ||
      day === '' ||
      year === ''
      ) {
        alert('Please fill all the fields');
        return;
        }


    if (year.length < 4) {
      alert('Please enter valid year not less than 4 numbers ex:1995');
      return;
    }

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    if (age < 21) {
      alert('You must be at least 21 years old.');
       return;
    };
    
    updateRegister({ firstName, lastName, dob, qualification });
  };
  
  useEffect(() => {
      if (updated) {
        return history.push('/Address');
      }
      return () => {
        setState({
          listElem: 1,
          firstName: '',
          lastName: '',
          qualification: "Certified Security Guard",
          month: '',
          day: '',
          year: '',
          showQuali: false,
        });
      };
    }, [updated, message, history]);

    const toggleQualification = () => {
    const doesShow = state.showQuali;
    setState({ ...state, showQuali: !doesShow });
  };

  const {
    listElem,
    firstName,
    lastName,
    qualification,
    month,
    day,
    year,
    showQuali,
  } = state;

  return (
    <Layout
      style="register flex dash-bgcolor w-full h-full fixed"
      imageURL={personal_info}
      imageSize="250"
      imagePosition="90"
    >
      <div className="flex w-full h-full">
        <Sidebar listActive={listElem} liObject={listObject} />
        <div className="flex-col w-full h-full md:mr-16">
          <div className="text-center pt-20">
            <Title
              customStyle="md:text-4xl text-xl font-medium p-4 title-color"
              value="Please enter your personal info"
            />
            <Subtitles
              customStyle="text-xs p-4 pt-2 sub-color"
              value="Kindly fill out your personal information to complete the registration process."
            />
            <PersonalForm
              handleChange={handleInput}
              handleSubmit={handleSubmit}
              firstName={firstName}
              lastName={lastName}
              qualification={qualification}
              month={month}
              day={day}
              year={year}
              btnOnclick={toggleQualification}
              toogleBtnList={showQuali}
            />
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(PersonalInfo);
