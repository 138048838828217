import React, { useContext } from 'react';
import { AppBar, Box, Button, Menu, MenuItem, styled, Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import logo_black from '../../../assets/img/logo_black.png'
import logo_white from '../../../assets/img/iss_logo.png'
import './Navbar.scss'
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import AuthContext from './../../../context/auth/authContext';


const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

const LogoBox = styled(Box)({
    display: 'flex'
})

const MenuBox = styled(Box)({
    display: 'flex',
    gap: 30,
    textAlign: 'center',
    alignItems: 'center'
})
const IconBox = styled(Box)({
    display: 'flex',
})

const ButtonBox = styled(Box)({
    display: 'flex'
})

const MenuItems = [
    { Name: 'Courses', Link: '/courses' },
    { Name: 'Why ISSAcademy', Link: '/whyISSA' },
    { Name: 'About us', Link: '/aboutus' },
    { Name: 'Blog', Link: '/blog' },
    { Name: 'FAQ', Link: '/faq' },
    // { Name: 'Help', Link: '/help' },
    { Name: 'Contact Us', Link: '/contactus' }
]


const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: '#FFF !important',
        color: '#000 !important',
        boxShadow: 'none !important',
        borderBottom: '1px solid #DEDEDE'
    },
    darkAppBar: {
        backgroundColor: '#071E30 !important',
        color: '#fff !important',
        boxShadow: 'none !important'
    }
}));

const Navbar = ({ history, faq, course, isHome }) => {
    const [open, SetOpen] = useState(false)
    const location = useLocation()
    const authContext = useContext(AuthContext);
    const { logOut } = authContext;
    const classes = useStyles();

    const handleLogOut = (e) => {
        e.preventDefault();
        history.push('/');
        logOut();
    };


    return (
        <AppBar className={isHome ? classes.darkAppBar : classes.appBar}>
            <StyledToolbar>
                <LogoBox className="logo__box">
                    {!isHome && (
                        <img src={logo_black} alt="ISS Academy logo" className='logo__image' />
                    )}
                    {isHome && (
                        <img src={logo_white} alt="ISS Academy logo" className='logo__image' />
                    )}
                    <Typography className='logo__text'
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: 'flex',
                        }}
                    >
                        INTERNATIONAL SECURITY<br />& SAFETY ACADEMY
                    </Typography>
                </LogoBox>
                <MenuBox sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                    {/* {MenuItems.map((item, index) => ( */}
                    {course && (
                        <Link to={'/courses'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    fontWeight: location.pathname === '/courses' ? '700' : '600',
                                    color: location.pathname === '/courses' ? '#28d79c' : ''
                                }}
                            >
                                Courses
                            </Typography>
                        </Link>
                    )}
                    {!isHome && (
                        <Link to={'/whyissa'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    fontWeight: location.pathname === '/whyissa' ? '700' : '600',
                                    color: location.pathname === '/whyissa' ? '#28d79c' : ''
                                }}
                            >
                                Why ISSAcademy
                            </Typography>
                        </Link>
                    )}
                    {!isHome && (
                        <Link to={'/aboutus'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    fontWeight: location.pathname === '/aboutus' ? '700' : '600',
                                    color: location.pathname === '/aboutus' ? '#28d79c' : ''
                                }}
                            >
                                About us
                            </Typography>
                        </Link>
                    )}
                    {!isHome && (
                        <Link to={'/blog'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    fontWeight: location.pathname === '/blog' ? '700' : '600',
                                    color: location.pathname === '/blog' ? '#28d79c' : ''
                                }}
                            >
                                Blog
                            </Typography>
                        </Link>
                    )}
                    {faq && (
                        <Link to={'/faq'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    fontWeight: location.pathname === '/faq' ? '700' : '600',
                                    color: location.pathname === '/faq' ? '#28d79c' : ''
                                }}
                            >
                                FAQ
                            </Typography>
                        </Link>
                    )}
                    {!isHome && (
                        <Link to={'/contactus'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    fontWeight: location.pathname === '/contactus' ? '700' : '600',
                                    color: location.pathname === '/contactus' ? '#28d79c' : ''
                                }}
                            >
                                Contact us
                            </Typography>
                        </Link>
                    )}
                    {/* ))} */}
                    {localStorage.getItem('token') ? (
                        <ButtonBox>
                            <Button
                                onClick={handleLogOut}
                                className="login__btn" variant="contained" sx={{ borderRadius: '30px', background: '#eb445a', '&:hover': {
                                    background: '#ed576b !important'}, textTransform: 'inherit', fontWeight: '600' }}>
                                Logout
                            </Button>
                        </ButtonBox>
                    ) : (
                        <>
                        <ButtonBox>
                            <Button
                                onClick={() => history.push('/Login')}
                                className="login__btn" variant="contained" sx={{ borderRadius: '30px', color: '#071E30', background: '#28d79c', '&:hover': {
                                    background: '#3edba6 !important'}, textTransform: 'inherit', fontWeight: '600' }}>
                                Login
                            </Button>
                        </ButtonBox>
             <ButtonBox>
            <Button
                onClick={() => history.push('/Register')} // Adjust the route as per your application
                className="login__btn" variant="contained" sx={{
                    borderRadius: '30px', color: '#071E30', background: '#28d79c','&:hover': {
                        background: '#3edba6 !important'}, textTransform: 'inherit', fontWeight: '600'}}>
                Signup
            </Button>
        </ButtonBox>
</>
                    )}
                </MenuBox>

                <MenuIcon sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
                    onClick={() => SetOpen(!open)}
                />

            </StyledToolbar>

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                open={open}
                onClose={() => SetOpen(!open)}
                onClick={() => SetOpen(!open)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ width: 350, height: '90vh' }}>
                    {course && (
                        <Link to={'/courses'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    padding: '10px',
                                    fontWeight: location.pathname === '/courses' ? '700' : '600',
                                    color: location.pathname === '/courses' ? '#28d79c' : ''
                                }}
                            >
                                Courses
                            </Typography>
                        </Link>
                    )}
                    {!isHome && (
                        <Link to={'/whyissa'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    padding: '10px',
                                    fontWeight: location.pathname === '/whyissa' ? '700' : '600',
                                    color: location.pathname === '/whyissa' ? '#28d79c' : ''
                                }}
                            >
                                Why ISSAcademy
                            </Typography>
                        </Link>
                    )}
                    {!isHome && (
                        <Link to={'/aboutus'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    padding: '10px',
                                    fontWeight: location.pathname === '/aboutus' ? '700' : '600',
                                    color: location.pathname === '/aboutus' ? '#28d79c' : ''
                                }}
                            >
                                About us
                            </Typography>
                        </Link>
                    )}
                    {!isHome && (
                        <Link to={'/blog'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    padding: '10px',
                                    fontWeight: location.pathname === '/blog' ? '700' : '600',
                                    color: location.pathname === '/blog' ? '#28d79c' : ''
                                }}
                            >
                                Blog
                            </Typography>
                        </Link>
                    )}
                    {faq && (
                        <Link to={'faq'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    padding: '10px',
                                    fontWeight: location.pathname === '/faq' ? '700' : '600',
                                    color: location.pathname === '/faq' ? '#28d79c' : ''
                                }}
                            >
                                FAQ
                            </Typography>
                        </Link>
                    )}
                    {!isHome && (
                        <Link to={'/contactus'} style={{ textDecoration: 'none' }}>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    padding: '10px',
                                    fontWeight: location.pathname === '/contactus' ? '700' : '600',
                                    color: location.pathname === '/contactus' ? '#28d79c' : ''
                                }}
                            >
                                Contact us
                            </Typography>
                        </Link>
                    )}
                </Box>
            </Menu>
        </AppBar>
    )
}

export default Navbar;



