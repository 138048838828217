import React, { useState, useContext, useEffect } from "react";

import { withRouter } from "react-router-dom";
import AccountForm from "../../../components/Forms/RegisterForm/Account/AccountForm";
import Layout from "../../../components/Elements/Layout/Layout";
import Sidebar from "../../../components/Fields/Sidebar/Sidebar";
import Title from "../../../components/Fields/Title/Title";
import Subtitles from "../../../components/Fields/Subtitles/Subtitles";
import AuthContext from "../../../context/auth/authContext";
import { listObject } from "../../../components/Constant";

export const Account = ({ account, history }) => {
  const authContext = useContext(AuthContext);
  const { passwordSet, setPass } = authContext;
  const email = localStorage.getItem("email");

  const [state, setState] = useState({
    listElem: 1,
    password: "",
    confPass: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== "" && confPass === password) {
      setPass({ password });
    } else {
      alert("Both passwords must match");
      setState("");
    }
  };

  useEffect(() => {
    if (passwordSet) {
      history.push("/PersonalInfo");
    }
  }, [passwordSet, history]);

  const { listElem, password, confPass } = state;

  return (
    <Layout style="register flex dash-bgcolor w-full h-full fixed">
      <div className="flex w-full h-full">
        <Sidebar listActive={listElem} liObject={listObject} />
        <div className="flex-col w-full h-full mr-24">
          <div className="text-center pt-20">
            <Title
              customStyle="text-4xl font-medium p-4 title-color"
              value="Please set your password"
            />
            <Subtitles
              customStyle="text-xs p-4 sub-color"
              value="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantiu"
            />
            <AccountForm
              handleChange={handleInput}
              handleSubmit={handleSubmit}
              password={password}
              confPass={confPass}
              classVal={{ width: 447 }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(Account);
