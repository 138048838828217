import { Box, Button, Grid, Input, InputAdornment, Typography } from '@mui/material'
import React from 'react'
import './Footer.scss'
import logo from '../../../assets/img/iss_logo.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


function Footer() {
  return (
    
      <Grid xs={12} container className='footer__grid'>
        <Grid xs={12} md={4} className='text__grid'>
          <div className='footer__logo'>
            <img src={logo} alt="ISS Academy logo" className='logoImage' />
            <Typography className='logoText'
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2
              }}
            >
              INTERNATIONAL SECURITY<br />& SAFETY ACADEMY
            </Typography>
          </div>
          <Grid item xs={12} className='desc__grid'>
            <Typography className='description'>
              ISSAcademy is the future of Security education. With decades of security training experience by our expertise, the International Security & Safety Academy (ISSA), is a security school, that is revolutionizing the security training industry.
            </Typography>
          </Grid>
        </Grid>


        <Grid xs={12} md={4} className='btn__grid'>
          <Typography className='contact'>
            CONTACT
          </Typography>
          <Typography className='contact__info'>
            <a href="mailto:support@issacademy.com" className='contact__link'>support@issacademy.com</a>
          </Typography>
          <Typography className='contact__info'>
            <a href="tel:+442045797932" className='contact__link'>+44 204 579 7932</a>
          </Typography>
          <Typography className='contact__info'>
            London, United Kingdom
          </Typography>
        </Grid>

        <Grid xs={12} md={4} className='btn__grid'>
          <Typography className='newsletter'>
            NEWSLETTER
          </Typography>
          <Typography className='contact__info'>
            Subscribe our newsletter to know more.
          </Typography>

          <Input
            className='email__input'
            placeholder='Enter your email'
            id="input-with-icon-adornment"
            endAdornment={
              <InputAdornment position="end" className='arrow__icon'>
                <ArrowForwardIcon style={{ cursor: 'pointer' }} />
              </InputAdornment>
            }
            inputProps={{ style: { color: '#777E90', paddingLeft: '20px' } }}
            disableUnderline={true}
          />
        </Grid>
        <Grid item xs={12} className='rights__grid'>
          <Typography className='rights'>
            Copyright © 2021 International Security & Safety Academy. All rights reserved.
          </Typography>
        </Grid>
      </Grid>


  )
}

export default Footer
