import React from 'react';

const Footer = ({ className }) => {
  return (
    <div className="footer bg-transparent py-4">
      <div className="container mx-auto flex items-center justify-center flex-col px-4">
        <div className={className}>
          <p class="text-center text-xs leading-6 text-gray-400">
            © 2021 International Security & Safety Academy. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
