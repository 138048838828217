const terms = () => [
  {
    id: "term1",
    content:
      "By using our site and service, you confirm that you accept all our general terms of use and that you agree to comply with them.",
  },
  {
    id: "term2",
    content:
      "You agree to our privacy policy, which sets out the terms on which we process any personal data we collect from you during the time of registration. By using our site, you consent to such processing and you warrant that all data provided by you is accurate.",
  },
  {
    id: "term3",
    content:
      "If you purchase services from our site, our terms and conditions of supply will apply to the sales.",
  },
];

export default terms;
