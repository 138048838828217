import React from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Pagination, Paper, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import image from '../../assets/img/Image (6).png';
import image_2 from '../../assets/img/Image (7).png';
import security from '../../assets/img/security.png';
import security1 from '../../assets/img/security1.png';
import security3 from '../../assets/img/security3.png';
import fire from '../../assets/img/fire.png';
import conflict from '../../assets/img/conflict.png';
import './Blog.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Navbar from '../../components/Elements/Navbar/Navbar';
import Footer from '../../components/Elements/Footer/Footer';


const theme = createTheme({
    palette: {
        primary: {
            main: '#FFF2F8', // Replace with your custom color
        },
    },
});


function Blog({ history }) {
    return (
        <Box className='blog__box'>
            <Navbar history={history} course={true} faq={true} />
            <Grid container xs={12} className='container'>
                <Grid container xs={12} className="blog__header">
                    <Grid xs={10} sm={10} md={8} lg={6} item className='item__grid'>
                        <Typography variant='h3' className='title__txt'>
                            Our Blog
                        </Typography>
                        <Typography variant='body2' className='body__txt'>
                            Gather insights about upcoming course and how to enhance your knowledge gathered from the courses.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12} className="cards__container">

                    {/* <Grid container xs={10} sm={10} md={10} lg={10} className='recent__grid'>
                        <Grid item className='title__grid'>
                            <Typography variant='h5'>
                                Recent blog posts
                            </Typography>
                        </Grid>
                        <Grid container className='card__grid'>
                            <Card sx={{ maxWidth: 345 }} className="card">
                                <CardMedia
                                    component="img"
                                    image={image}
                                    className='cardMedia'
                                />
                                <CardContent className='card__content'>
                                    <Typography gutterBottom variant="caption" component="div" className='info__txt'>
                                        Olivia Rhye • 20 Jan 2022
                                    </Typography>
                                    <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                        Security Manned Training
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='card__description'>
                                        Lizards are a widespread group of squamate reptiles, with over 6,000
                                        species, ranging across all continents except Antarctica
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ padding: '0px', marginTop: '15px' }}>
                                    <Button disabled className='read__more__btn'>Read More <ArrowForwardIcon fontSize='small' style={{ marginLeft: '10px' }} /></Button>
                                </CardActions>
                            </Card>

                            <Card sx={{ maxWidth: 345 }} className="card">
                                <CardMedia
                                    component="img"
                                    image={image}
                                    className='cardMedia'
                                />
                                <CardContent className='card__content'>
                                    <Typography gutterBottom variant="caption" component="div" className='info__txt'>
                                        Olivia Rhye • 20 Jan 2022
                                    </Typography>
                                    <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                        Security Manned Training
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='card__description'>
                                        Lizards are a widespread group of squamate reptiles, with over 6,000
                                        species, ranging across all continents except Antarctica
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ padding: '0px', marginTop: '15px' }}>
                                    <Button disabled className='read__more__btn'>Read More <ArrowForwardIcon fontSize='small' style={{ marginLeft: '10px' }} /></Button>
                                </CardActions>
                            </Card>

                            <Card sx={{ maxWidth: 345 }} className="card">
                                <CardMedia
                                    component="img"
                                    image={image}
                                    className='cardMedia'
                                />
                                <CardContent className='card__content'>
                                    <Typography gutterBottom variant="caption" component="div" className='info__txt'>
                                        Olivia Rhye • 20 Jan 2022
                                    </Typography>
                                    <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                        UX review presentations
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='card__description'>
                                        Lizards are a widespread group of squamate reptiles, with over 6,000
                                        species, ranging across all continents except Antarctica
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ padding: '0px', marginTop: '15px' }}>
                                    <Button disabled className='read__more__btn'>Read More <ArrowForwardIcon fontSize='small' style={{ marginLeft: '10px' }} /></Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid> */}


                    <Grid container xs={10} sm={10} md={10} lg={10} className='all__blogs__grid'>
                        <Grid item className='title__grid'>
                            <Typography variant='h5'>
                                All blog posts
                            </Typography>
                        </Grid>
                        <Grid container className='card__grid'>
                            <Card sx={{ maxWidth: 345 }} className="card">
                                <CardMedia
                                    component="img"
                                    image={security}
                                    className='cardMedia'
                                />
                                <CardContent className='card__content'>
                                    {/* <Typography gutterBottom variant="caption" component="div" className='info__txt'>
                                        Olivia Rhye • 20 Jan 2022
                                    </Typography> */}
                                    <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                        Essensial equipment security guards must carry while on duty
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='card__description'>
                                    The world is a scarier place and we rely on security guards more than ever for our protection from threats and violence.
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ padding: '0px', marginTop: '15px' }}>
                                    <Button href='https://landmarksecurity.ae/10-essential-equipment-security-guards-must-carry-while-on-duty/' target="_blank" className='read__more__btn'>Read More <ArrowForwardIcon fontSize='small' style={{ marginLeft: '10px' }} /></Button>
                                </CardActions>
                            </Card>

                            <Card sx={{ maxWidth: 345 }} className="card">
                                <CardMedia
                                    component="img"
                                    image={security3}
                                    className='cardMedia'
                                />
                                <CardContent className='card__content'>
                                    {/* <Typography gutterBottom variant="caption" component="div" className='info__txt'>
                                        Olivia Rhye • 20 Jan 2022
                                    </Typography> */}
                                    <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                    Security Patrol duties everybody should know
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='card__description'>
                                    Conducting security patrol is one of the primary duties of the security personnel. There is the various aim of the patrol depending on the site.
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ padding: '0px', marginTop: '15px' }}>
                                    <Button href='https://www.securityguardexam.com/security-patrol-duties-everybody-should-know/' target="_blank" className='read__more__btn'>Read More <ArrowForwardIcon fontSize='small' style={{ marginLeft: '10px' }} /></Button>
                                </CardActions>
                            </Card>

                            <Card sx={{ maxWidth: 345 }} className="card">
                                <CardMedia
                                    component="img"
                                    image={fire}
                                    className='cardMedia'
                                />
                                <CardContent className='card__content'>
                                    {/* <Typography gutterBottom variant="caption" component="div" className='info__txt'>
                                        Olivia Rhye • 20 Jan 2022
                                    </Typography> */}
                                    <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                    5 things you should never do in a fire and what to do instead
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='card__description'>
                                    What you should do in the event of a fire · Use the appropriate fire extinguisher · Call the emergency services · Escape
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ padding: '0px', marginTop: '15px' }}>
                                    <Button href='https://fire-extinguisher-maintenance.co.uk/blog/5-things-you-should-never-do-in-a-fire/' target="_blank" className='read__more__btn'>Read More <ArrowForwardIcon fontSize='small' style={{ marginLeft: '10px' }} /></Button>
                                </CardActions>
                            </Card>

                            <Card sx={{ maxWidth: 345 }} className="card">
                                <CardMedia
                                    component="img"
                                    image={conflict}
                                    className='cardMedia'
                                />
                                <CardContent className='card__content'>
                                    {/* <Typography gutterBottom variant="caption" component="div" className='info__txt'>
                                        Olivia Rhye • 20 Jan 2022
                                    </Typography> */}
                                    <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                    Expert tips for Conflict Management for every personality type
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='card__description'>
                                    Conflict management is the practice of being able to identify and managing conflicts sensibly, fairly, and efficiently.
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ padding: '0px', marginTop: '15px' }}>
                                    <Button href='https://blog.hubspot.com/service/conflict-management-styles' target="_blank" className='read__more__btn'>Read More <ArrowForwardIcon fontSize='small' style={{ marginLeft: '10px' }} /></Button>
                                </CardActions>
                            </Card>

                            <Card sx={{ maxWidth: 345 }} className="card">
                                <CardMedia
                                    component="img"
                                    image={security1}
                                    className='cardMedia'
                                />
                                <CardContent className='card__content'>
                                    {/* <Typography gutterBottom variant="caption" component="div" className='info__txt'>
                                        Olivia Rhye • 20 Jan 2022
                                    </Typography> */}
                                    <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                    Seven Strategies for Sustainable Peace
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='card__description'>
                                    Most social conflicts (and particularly the more difficult ones) are up and down the hierarchy of power and authority.
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ padding: '0px', marginTop: '15px' }}>
                                    <Button href='https://icccr.tc.columbia.edu/practice/tools-and-assessments/seven-strategies-for-sustainable-peace/#:~:text=We%20have%20identified%20seven%20main,rules%20as%20a%20last%20resort' target="_blank" className='read__more__btn'>Read More <ArrowForwardIcon fontSize='small' style={{ marginLeft: '10px' }} /></Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        {/* <Grid container justifyContent="center" style={{ marginTop: '40px' }}>
                            <Pagination color="primary" className='pagination' count={10} shape="rounded" />
                        </Grid> */}


                    </Grid>




                </Grid>

            </Grid>
            <Footer />
        </Box>
    )
}

export default Blog