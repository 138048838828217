import React, { useState, useContext, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import ConfirmEmail from '../../../components/Forms/RegisterForm/ConfirmEmail/ConfirmEmail';
import Sidebar from '../../../components/Fields/Sidebar/Sidebar';
import Title from '../../../components/Fields/Title/Title';
import Subtitles from '../../../components/Fields/Subtitles/Subtitles';
import Layout from '../../../components/Elements/Layout/Layout';
import email_confirmation from '../../../assets/images/email_confirmation.png';
import { listObject } from '../../../components/Constant';
import AuthContext from '../../../context/auth/authContext';
import Footer from '../../../components/Fields/Footer/Footer';

export const VerificationCode = ({ verification, history }) => {
  const authContext = useContext(AuthContext);
  const { verified, message, verifyCode, resendEmail } = authContext;
  const [status, setStatus] = useState('');
  const email = localStorage.getItem('email');
  const [state, setState] = useState({
    code: '',
    listElem: 0,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    verifyCode({ email, verificationCode: code });
  };

  const handleResend = (e) => {
    e.preventDefault();
    setStatus(true);
    resendEmail({ email });
    setTimeout(() => {
      setStatus(false);
      setTimeout(() => {
        setStatus('');
      }, 4000);
    }, 4000);
  };

  useEffect(() => {
    if (verified) {
      history.push('/PersonalInfo');
    }
    if (message && message === 'Incorrect Code') {
      alert(message);
    }
  }, [verified, message, history]);

  const { code, listElem } = state;

  return (
    <Layout
      style="register flex dash-bgcolor w-full h-full fixed"
      imageURL={email_confirmation}
    >
      <div className="flex w-full h-full">
        <Sidebar listActive={listElem} liObject={listObject} />
        <div className="flex-col w-full h-full md:mr-32 flex">
          <div className="text-center pt-20">
            <Title
              customStyle="text-3xl font-medium pt-4 title-color"
              value="Confirm your email"
            />
            <Subtitles
              customStyle="text-xs p-4 sub-color"
              value={`We've sent a 6-digit verification code to ${
                email || ''
              } `}
            />
          </div>
          <ConfirmEmail
            handleChange={handleInput}
            handleSubmit={handleSubmit}
            code={code}
            handleResend={handleResend}
          />
          {status !== '' && (
            <div>
              {console.log(status)}
              {status && (
                <span>
                  <i class="fa fa-spinner fa-spin mr-2"></i>Resend verification
                  code to {email}
                </span>
              )}
              {!status && (
                <span>
                  <i class="fa fa-check-circle  mr-2"></i>Successfully send to{' '}
                  {email}
                </span>
              )}
            </div>
          )}

          <Footer className="mt-20" />
        </div>
      </div>
    </Layout>
  );
};

VerificationCode.propTypes = {};

export default withRouter(VerificationCode);
