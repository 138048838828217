import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import logo from '../../../assets/images/logo.png';

import Transition from '../../../wrappers/Transition';
import { MdMenu, MdKeyboardBackspace } from 'react-icons/md';

import ButtonDynamic from '../Button/Button';
import AuthContext from './../../../context/auth/authContext';
import ExamTimer from '../Timer/Timer';

const Navbar = ({
  duration,
  history,
  examData,
  enableExam,
  changeExamStatus,
  slideOpen,
  slideStatus,
}) => {
  const authContext = useContext(AuthContext);
  const { logOut } = authContext;

  const [isOpen, setIsOpen] = useState(false);
  const handleLogOut = (e) => {
    e.preventDefault();
    history.push('/');
    logOut();
  };

  const navigate = (e) => {
    e.preventDefault();
    history.push('/user/profile');
  };
  return (
    <nav className="flex md:flex-row flex-col items-stretch justify-between flex-wrap prim-black w-full z-50 sticky">
      <div className="flex items-center justify-center flex-shrink-0 text-white p-2 pl-6 md:w-3/12">
        <div className="md:w-full">
          <img src={logo} className="h-15" />
        </div>
        <div className="md:hidden block flex items-center justify-center text-white  pl-3 w-6/12 ">
          <div className="relative inline-block text-left">
            <div className="flex justify-center items-center">
              <span className="rounded-md shadow-sm">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 bg-none text-white text-sm uppercase leading-5 tracking-widest focus:outline-none transition ease-in-out duration-150"
                  id="options-menu"
                  aria-haspopup={isOpen}
                  aria-expanded={isOpen}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  MY ACCOUNT
                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </span>
            </div>

            <Transition
              show={isOpen}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
                <div className="rounded-md bg-white shadow-xs">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                      role="menuitem"
                      onClick={navigate}
                    >
                      Profile
                    </a>
                    {/* <a
                    href="#"
                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                  >
                    Support
                  </a> */}
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                      role="menuitem"
                      onClick={handleLogOut}
                    >
                      Sign Out
                    </a>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
      <div className="flex  md:w-7/12 w-full md:pb-0 pb-4">
        {slideOpen ? (
          <MdKeyboardBackspace
            className="md:hidden block ml-8 dash-icons text-4xl"
            onClick={() => slideStatus(!slideOpen)}
          />
        ) : (
          <MdMenu
            className="md:hidden block ml-8 dash-icons text-4xl"
            onClick={() => slideStatus(!slideOpen)}
          />
        )}

        <div className="flex justify-center md:justify-end w-full items-center">
          {enableExam && (
            <ButtonDynamic
              clickHandler={() =>
                history.push('/exam/' + examData._id + '/' + examData.course)
              }
              btnText="Exam"
              customStyle="upgrade-btn uppercase text-xs font-bold mr-4 py-1 px-4 rounded-full focus:outline-none"
            />
          )}
          {duration !== 0 && (
            <div className="upgrade-btn uppercase text-xs font-bold mr-4 py-1 px-4 rounded-full focus:outline-none">
              <ExamTimer
                callBack={(e) => {
                  if (e < 2) {
                    changeExamStatus(true);
                  }
                }}
                text="Remaining:"
                totalHours={duration}
              />
            </div>
          )}
          {/* <IoIosHome className="ml-4 dash-icons-flip dash-icons text-sm" /> */}
          {/* <a
            onClick={() => history.push("/")}
            className="mr-3 ml-2 mt-1 dash-icons text-xs font-semibold uppercase tracking-widest"
          >
            Home
          </a> */}

          <button
            onClick={() => history.push('/')}
            className="border border-white-300 mr-5 px-5 py-1 rounded-full text-white text-sm uppercase"
          >
            Home
          </button>
          {/* <AiFillAppstore className="ml-4 dash-icons text-sm" />
          <a
            href="#"
            className="mr-3 ml-2 mt-1 dash-icons text-xs font-semibold uppercase tracking-widest"
          >
            Catalog
          </a> */}
          <button
            onClick={() => history.push('/Support')}
            className="border border-white-300 mr-5 px-5 py-1 rounded-full text-white text-sm uppercase"
          >
            Support & FAQ
          </button>
          {/* <IoIosHelpBuoy className="ml-4 dash-icons text-sm" />
          <a
            onClick={() => history.push("/Support")}
            className="mr-3 ml-2 mt-1 dash-icons text-xs font-semibold uppercase tracking-widest"
          >
            Support & FAQ
          </a> */}
        </div>
      </div>
      <div className="md:block hidden flex items-center justify-center text-white p-6 pl-3 w-2/12 ">
        <div className="relative inline-block text-left">
          <div className="flex justify-center items-center">
            <span className="rounded-md shadow-sm">
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 bg-none text-white text-sm uppercase leading-5 tracking-widest focus:outline-none transition ease-in-out duration-150"
                id="options-menu"
                aria-haspopup={isOpen}
                aria-expanded={isOpen}
                onClick={() => setIsOpen(!isOpen)}
              >
                MY ACCOUNT
                <svg
                  className="-mr-1 ml-2 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </span>
          </div>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
              <div className="rounded-md bg-white shadow-xs">
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                    onClick={navigate}
                  >
                    Profile
                  </a>
                  {/* <a
                    href="#"
                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                  >
                    Support
                  </a> */}
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                    onClick={handleLogOut}
                  >
                    Sign Out
                  </a>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  type: PropTypes.string,
};
export default Navbar;
