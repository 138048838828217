import React from 'react';
import PropTypes from 'prop-types';

const Title = ({
  customStyle,
  value,
}) => (
  <p className={customStyle}>{value}</p>
);
Title.propTypes = {
  customStyle: PropTypes.string,
  value: PropTypes.string,
};

export default Title;
