import React, { useState, useContext, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import Title from '../../components/Fields/Title/Title';
import Subtitles from '../../components/Fields/Subtitles/Subtitles';
import Logo from './../../components/Elements/Logo';
import PropTypes from 'prop-types';

import LogoImg from '../../assets/images/sign_up.png';

import AuthContext from '../../context/auth/authContext';
import Footer from '../../components/Fields/Footer/Footer';

export const Login = ({ history }) => {
  const authContext = useContext(AuthContext);
  const { authenticated, loginUser, message, resendEmail } = authContext;
  const [signPage, setSignInPage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    setState({ ...state, email: email, password: password });
    loginUser({ email, password });
  };

  useEffect(() => {
    if (authenticated) {
      setLoader(false);
      localStorage.setItem('auth', authenticated);
      // history.push('/course/details');
      history.push('/courses');
    }
  }, [authenticated, history]);

  useEffect(() => {
    if (message !== null) {
      setLoader(false);
      alert(message);
      if (message === 'User email is not verified') {
        resendEmail({ email: state.email });
        setTimeout(() => {
          history.push('/Verification');
        }, 100);
      }
    }
  }, [message]);

  const { email, password } = state;

  return (
    <>
      <div className="register flex dash-bgcolor w-full h-full fixed">
        <div className="flex-1">
          <div className="register--box py-56  md:px-32 text-justified ">
            <div>
              <Logo
                history={history}
                width="250px"
                height="40px"
                background="#f6faff"
                color="#242424"
                viewBox="10 0 199 40"
              />
            </div>
            <Title
              customStyle="text-2xl font-medium p-4 title-color"
              value="Sign in to your account to ISS Academy now!"
            />
            <Subtitles
              customStyle="text-xs p-4 sub-color"
              value="Login now to the most disruptive online security training program thats is revolutionizing the security industry."
            />
            <LoginForm
              handleChange={handleInput}
              email={email}
              password={password}
              handleSubmit={handleSubmit}
              loader={loader}
            />
            <div className="text-center items-start">
              <p className="text-xs">
                Don't have an account?&nbsp;
                <a href="/Register" className="inline-flex text-xs font-bold">
                  Sign Up
                </a>
              </p>
              <p className="mt-2 text-sm">
                <a
                  className="text-sm cursor-pointer hover:font-extrabold"
                  onClick={() => history.push('/')}
                >
                  Home
                </a>
                {' | '}
                <a
                  className="text-sm cursor-pointer hover:font-extrabold"
                  onClick={() => history.push('/support')}
                >
                  Support
                </a>
              </p>
              {/* <a href="#" onClick={() => setSignInPage(false)} className="text-xs inline-flex">Forgot Password?</a> */}
            </div>

            <Footer />
          </div>
        </div>
        <div className="md:block hidden">
          <img
            className="w-auto h-screen object-cover"
            src={LogoImg}
            alt="Login"
            id="login"
          />
        </div>
      </div>

      <Footer position="absolute" />
    </>
  );
};

Login.propTypes = {};

export default withRouter(Login);
