import { Chip, Grid, Stack } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import security from '../../assets/img/security4.jpg';
import cross from '../../assets/img/cross.png';
import gunman from '../../assets/img/gunman.png';
import truck from '../../assets/img/Image (5).png';
import './PopularCourses.scss'
import PersonIcon from '@mui/icons-material/Person';
import UpcomingIcon from '@mui/icons-material/Upcoming';


function PopularCourses() {
    return (
        <Grid xs={12} className='popularCourses'>
            <Grid item xs={12} className='title'>
                <h1 style={{ color: '#000', marginBottom: '3pc' }}>Popular courses</h1>
            </Grid>
            <Grid item xs={12}>
                <div className="card-stack">
                    <Card sx={{ maxWidth: 345 }} className="card">
                        <CardMedia
                            sx={{ height: 140 }}
                            image={security}
                            title="security"
                            className='cardMedia'
                        />
                        {/* <Stack direction="row" spacing={1} marginLeft={2}>
                            <Chip label="Security" className='chip chip1' />
                            <Chip label="Safety" className='chip chip2' />
                        </Stack> */}
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                Certified Security Guard
                            </Typography>
                            <Typography variant="body2" color="text.secondary" className='card__description'>
                            Provides essential training in access control, patrolling, emergency response, and conflict management, preparing students to work as professional security guards in various environments.
                            </Typography>
                        </CardContent>
                        <Stack direction="row" spacing={1} marginLeft={2} paddingBottom={3} paddingTop={1}>
                            <PersonIcon className='card__icon'/>
                            <Typography variant='subtitle2' textTransform='inherit' padding={0} className='btm__text'>6.3K enrolled</Typography>
                        </Stack>
                    </Card>

                    <Card sx={{ maxWidth: 345 }} className="card">
                        <CardMedia
                            sx={{ height: 140 }}
                            image={truck}
                            title="security"
                            className='cardMedia'
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                Certified Security Supervisor
                            </Typography>
                            <Typography variant="body2" color="text.secondary" className='card__description'>
                            Provides essential skills in security management, risk assessment, emergency response planning, and personnel management, enabling students to lead security teams and ensure safety and security.
                            </Typography>
                        </CardContent>
                        <Stack direction="row" spacing={1} marginLeft={2} paddingBottom={3} paddingTop={1}>
                            <UpcomingIcon className='card__icon'/>
                            <Typography variant='subtitle2' textTransform='inherit' padding={0} className='btm__text'>Coming soon</Typography>
                        </Stack>
                    </Card>

                    <Card sx={{ maxWidth: 345 }} className="card">
                        <CardMedia
                            sx={{ height: 140 }}
                            image={cross}
                            title="security"
                            className='cardMedia'
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" className='card__title'>
                               Certified CCTV Engineer
                            </Typography>
                            <Typography variant="body2" color="text.secondary" className='card__description'>
                            Learn the latest techniques in designing, installing, and maintaining CCTV systems with the Certified CCTV Engineer course, and become equipped to ensure safety and security in a range of environments.
                            </Typography>
                        </CardContent>
                        <Stack direction="row" spacing={1} marginLeft={2} paddingBottom={3} paddingTop={1}>
                            <UpcomingIcon className='card__icon'/>
                            <Typography variant='subtitle2' textTransform='inherit' padding={0} className='btm__text'>Coming soon</Typography>
                        </Stack>
                    </Card>

                    <Card sx={{ maxWidth: 345 }} className="card">
                        <CardMedia
                            sx={{ height: 140 }}
                            image={gunman}
                            title="security"
                            className='cardMedia'
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                Certification in Control Room Management
                            </Typography>
                            <Typography variant="body2" color="text.secondary" className='card__description'>
                            Learn about the latest technologies, standards, and best practices in control room management and gain practical experience in developing and implementing control room procedures.
                            </Typography>
                        </CardContent>
                        <Stack direction="row" spacing={1} marginLeft={2} paddingBottom={3} paddingTop={1}>
                            <UpcomingIcon className='card__icon'/>
                            <Typography variant='subtitle2' textTransform='inherit' padding={0} className='btm__text'>Coming soon</Typography>
                        </Stack>
                    </Card>

                </div>

            </Grid>
            {/* <Grid xs={12} style={{textAlign:'center'}}>
                <Button variant="contained" className='more__courses__btn'>View more courses <ArrowForwardIcon fontSize='small' style={{ marginLeft: '5px' }} /></Button>
            </Grid> */}

        </Grid>
    )
}

export default PopularCourses;
