import React, { useState, useContext, useEffect } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import terms from '../../pages/Register/Legal/mock';
import TimePicker from '../../components/Course/TimePIcker';
import AuthContext from '../../context/auth/authContext';
import ExamTimer from '../Fields/Timer/Timer';
import clientAxios from '../../config/axios';
import Loader from '../Fields/Loader/Loader';

const BookExam = ({
  changeStatus,
  examData,
  sloatDetails,
  history,
  examStatus,
  disableExam,
}) => {
  const [examregistered, setexamregistered] = useState(false);
  const [model, setModel] = useState({
    slot: '',
  });
  const [conditions, setCondition] = useState(true);
  const [termsVal, setTerms] = useState([]);
  const [slots, setSlots] = useState([]);
  const [loader, setLoader] = useState(false);
  const [examPassed, setExamPassed] = useState({});

  const authContext = useContext(AuthContext);
  const { sendExamSchedule, getAttemptByExamId, attemptUser } = authContext;

  const scheduleExam = () => {
    if (model.slot !== '') {
      setLoader(true);
      const data = {
        exam: examData._id,
        slot: model.slot,
      };
      sendExamSchedule(data);
      setTimeout(() => {
        setexamregistered(true);
        setLoader(false);
      }, 1000);
    } else {
      alert('Select date and time');
    }
  };

  const checkedTerms = (e) => {
    if (e.target.checked) {
      setTerms([...termsVal, e.target.id]);
    } else {
      const value = termsVal.filter((item) => item !== e.target.id);
      setTerms(value);
    }
  };

  const shouldDateBeSelected = (date) => {
    const dateFormat = moment(date).format('YYYY-MM-DD');
    let status = null;
    if (examData.slots) {
      examData.slots.filter((e) => {
        if (moment(e.timestamp).format('YYYY-MM-DD') === dateFormat) {
          if (moment() <= moment(e.timestamp)) {
            status = 'active';
          } else {
            status = 'inactive';
          }
        }
      });
    }
    return status;
  };

  const getSlots = (date) => {
    const dateFormat = moment(date).format('YYYY-MM-DD');
    const times = [];
    examData.slots.map((e) => {
      if (moment(e.timestamp).format('YYYY-MM-DD') === dateFormat) {
        const time = moment.utc(e.timestamp).local().format('hh:mm A');
        times.push({ slot: e._id, time: time });
      }
    });
    setSlots(times);
  };

  const items = terms();
  let dateTime,
    text = null;
  let examBtn = false;
  let timer = false;

  if (sloatDetails !== null) {
    const value = sloatDetails.split('?');
    text = value[0];
    dateTime = value[1];
    if (text.includes('Your exam started at')) {
      examBtn = true;
    } else if (text.includes('Your exam begings in')) {
      timer = true;
      dateTime = value[1];
    }
  }
  useEffect(() => {
    const status = examStatus.progress.filter(
      (e) => e.exam && e.status === 'complete'
    );
    if (status.length) disableExam(false);
    if (examStatus.length > 1) {
      getAttemptByExamId(examStatus.progress[1].exam);
    }
  }, [examStatus]);

  useEffect(() => {
    if (attemptUser) {
      const data = attemptUser.filter(
        (e) => e.qualificationStatus === 'passed'
      );
      if (data.length) {
        const response = getCertificate(data[0]._id);
        response.then((e) => {
          if (e.status === 200) {
            const certificate = e.data.filter((e) => e.user === data[0].user);
            if (certificate.length) {
              setExamPassed({
                status: true,
                qualificationStatus: true,
                certificate: certificate[0],
              });
            }
          }
        });
      } else {
        const data = attemptUser.filter(
          (e) => e.qualificationStatus === 'failed'
        );
        if (data.length) {
          setExamPassed({
            status: true,
            qualificationStatus: false,
            certificate: {},
          });
        }
      }
    }
  }, [attemptUser]);

  const getCertificate = async (id) => {
    const token = localStorage.getItem('token');
    const urlBackEnd = process.env.REACT_APP_BACKEND_URL;
    const response = await clientAxios.get(
      urlBackEnd,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { status: response.status, data: response.data };
  };
  return (
    <div className=" flex flex-col w-full h-full items-center justify-center">
      {examPassed.status ? (
        examPassed.qualificationStatus ? (
          <div className="p-4">
            <h1 className="font-medium text-2xl uppercase  text-center">
              {examPassed.certificate.course.name}
            </h1>
            <p className="text-sm text-center">
              Congratulations! You have successfully passed with a score of
              {' ' + examPassed.certificate.percentage} out of 100.
            </p>
            <button
              className="btn-fl grid m-auto  mt-4 hover:btn-hv text-white  py-2 p-12 mt-4 rounded-full"
              type="submit"
              onClick={() =>
                history.push('/certificate/' + examPassed.certificate._id)
              }
            >
              Certificate
            </button>
          </div>
        ) : (
          <div className="p-4">
            <h1 className="font-medium text-2xl uppercase  text-center">
              Certified Security Guard(CSG)
            </h1>
            <p className="text-sm text-center">
              Sorry! You have failed. Kindly reschedule the exam to get
              certified.
            </p>
            <div className="flex  justify-center">
              <button
                className="btn-fl mt-4 hover:btn-hv text-white  py-2 p-8 mt-4 rounded-full"
                type="submit"
                onClick={() =>
                  history.push('/certificate/' + examPassed.certificate._id)
                }
              >
                Back to Course
              </button>
              <button
                className="btn-fl upgrade-btn ml-4 mt-4 hover:btn-hv text-white  py-2 p-8 rounded-full"
                type="submit"
                onClick={() =>
                  history.push('/course/60ac8f7e9276872889ed83e3/payment')
                }
              >
                Schedule Exam
              </button>
            </div>
          </div>
        )
      ) : (
        <>
          {!examregistered && (
            <>
              <h1 className="font-medium text-2xl uppercase">Exam Schedule </h1>
              <p className=" mb-3 text-sm text-gray-400 uppercase">
                {examData.name}
              </p>
            </>
          )}
          {sloatDetails !== null ? (
            <div>
              {timer ? (
                <ExamTimer
                  callBack={(e) => {}}
                  text={text}
                  totalHours={'000' + dateTime}
                />
              ) : (
                <div>
                  <div>{text + ' ' + dateTime}</div>
                  {examBtn && (
                    <button
                      className="ml-8 btn-fl hover:btn-hv text-white mt-4 bg-gray-800  py-2 p-24 rounded-full"
                      onClick={() =>
                        history.push(
                          '/exam/' + examData._id + '/' + examData.course
                        )
                      }
                    >
                      Start Exam
                    </button>
                  )}
                </div>
              )}
            </div>
          ) : conditions ? (
            <div className="text-center">
              <div className="mt-8">
                {items.map((term, index) => (
                  <div
                    className="flex w-1/3 m-auto text-justify px-4 py-2 relative items-start"
                    key={index}
                  >
                    <div class="flex items-center h-5">
                      <input
                        type="checkbox"
                        className="form-checkbox mt-2 h-4 w-4 transition duration-150 ease-in-out"
                        id={term.id}
                        value={term.id}
                        onChange={(e) => checkedTerms(e)}
                      />
                    </div>
                    <div class="ml-3 text-sm leading-5">
                      <label htmlFor={term.id} className="text-xs">
                        {term.content}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="btn-fl hover:btn-hv text-white  py-2 p-24 mt-8 rounded-full"
                type="submit"
                onClick={() => {
                  if (termsVal.length === 3) {
                    setCondition(false);
                  } else {
                    alert('Please accept the terms & conditions');
                  }
                }}
              >
                Get Started
              </button>
            </div>
          ) : (
            <>
              {examregistered && (
                <>
                  <h1 className="font-medium text-2xl uppercase">
                    {examData.name}
                  </h1>
                  <p className=" mb-3 text-sm text-gray-400 uppercase">
                    Exam schedule confirmation
                  </p>
                  <div className="border-t-1 p-2">
                    <p className="text-gray-600 mt-2 text-sm">
                      Your exam has been scheduled successfully.
                    </p>
                    <p className="text-gray-600 text-sm">
                      Following are the details of your exam schedule:
                    </p>
                    <p className="text-gray-600 text-sm">
                      Exam: Certified Security Guard (CSG)
                    </p>
                    <p className="text-gray-600 text-sm">
                      Duration: 60 minutes
                    </p>
                    <p className="text-gray-600 text-sm">Venue: Online</p>
                  </div>
                </>
              )}
              {!examregistered && (
                <div className=" mt-4 flex w-full max-w-4xl">
                  <div className="w-1/3 prim-black p-3 py-10 px-10 text-white h-full rounded-lg">
                    <h3 className="bg-gray-500 mb-2 p-1 px-1/12 rounded-full text-xs w-max-content">
                      Certified Security Guard
                    </h3>
                    <h1 className="font-medium leading-0 my-6 text-xl">
                      Schedule Exam
                    </h1>
                    <p className="leading-6 text-gray-200 text-sm">
                      Exams will be scheduled online on a specific day and time
                      set by the student. The duration of each exam will be 45
                      mins. Rescheduling the exams can be availed only once. A
                      confirmation email will be sent once scheduled.
                    </p>
                  </div>
                  <div className=" flex course-calendar ml-4 card">
                    <div className="m-4">
                      <Calendar
                        tileClassName={({ date }) => {
                          if (shouldDateBeSelected(date) === 'active') {
                            return 'react-calendar__tile--active';
                          } else {
                            if (shouldDateBeSelected(date) === 'inactive') {
                              return 'react-calendar__tile--inactive';
                            }
                          }
                          return null;
                        }}
                        onClickDay={(e) => getSlots(e)}
                      />
                    </div>
                    {slots.length ? (
                      <div className="mt-6">
                        <TimePicker
                          onSelect={(e) => setModel({ ...model, slot: e })}
                          selectedTime={model.slot}
                          slots={slots}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              )}
              {examregistered ? (
                <button
                  className="btn-fl hover:btn-hv text-white mt-4 bg-gray-800 py-2 p-12   rounded-full"
                  onClick={() => {
                    setexamregistered(false);
                    changeStatus(false);
                  }}
                >
                  Back To Course
                </button>
              ) : (
                <button
                  className="btn-fl hover:btn-hv text-white mt-4 bg-gray-800 py-2 p-24 rounded-full"
                  onClick={() => scheduleExam()}
                >
                  {loader ? <Loader /> : 'Schedule'}
                </button>
              )}
            </>
          )}
          {conditions && <div className="teamrs-side"></div>}
        </>
      )}
    </div>
  );
};

export default BookExam;
