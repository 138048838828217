import React from 'react';
import PropTypes from 'prop-types';
import { MdVisibility } from 'react-icons/md';

import DynamicInput from '../../../Fields/DynamicInput/DynamicInput';
import Subtitles from '../../../Fields/Subtitles/Subtitles';

const AccountForm = ({
  handleChange,
  password,
  confPass,
  handleSubmit,
  classVal,
}) => (
  <form
    className="flexDirectionCol flex mx-64 px-16 mt-24"
    onSubmit={handleSubmit}
  >
    <div>
      <p className="text-xs font-medium w-full reg-label mb-1 text-left">
        PASSWORD
      </p>
      <DynamicInput
        type="password"
        placeholder=""
        handleChange={handleChange}
        value={password || ''}
        name="password"
        customStyle={classVal}
      />
      <p className="text-xs font-medium w-full reg-label mb-3 text-right pr-8 inline-block">
        Show{' '}
        <a href="#">
          <MdVisibility className="react-icons" />
        </a>
      </p>
      <p className="text-xs font-medium w-full reg-label mb-1 text-left">
        CONFIRM PASSWORD
      </p>
      <DynamicInput
        type="password"
        placeholder=""
        handleChange={handleChange}
        value={confPass || ''}
        name="confPass"
        customStyle={classVal}
      />
    </div>
    <button
      className="btn-fl hover:btn-hv text-white font-medium py-2 p-24 rounded-full"
      type="submit"
    >
      Get Started
    </button>
    <div className="flex py-4">
      <Subtitles customStyle="text-xs pl-4 sub-color" value="or press ENTER↵" />
    </div>
  </form>
);

AccountForm.propTypes = {
  handleChange: PropTypes.func,
  passwordText: PropTypes.string,
  confirmpass: PropTypes.string,
  handleSubmit: PropTypes.func,
  classVal: PropTypes.object,
};

export default AccountForm;
