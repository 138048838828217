import React, { useState, useContext } from 'react';

import { withRouter } from 'react-router-dom';
import Layout from '../../../components/Elements/Layout/Layout';
import Sidebar from '../../../components/Fields/Sidebar/Sidebar';
import Title from '../../../components/Fields/Title/Title';
import Subtitles from '../../../components/Fields/Subtitles/Subtitles';
import AuthContext from '../../../context/auth/authContext';

import { listObject } from '../../../components/Constant';
import address_image from '../../../assets/images/address.png';
import terms from './mock';
import Footer from '../../../components/Fields/Footer/Footer';

export const Legal = ({ legal, history }) => {
  const authContext = useContext(AuthContext);
  const [termsVal, setTerms] = useState([]);
  const { updated, message, updateRegister } = authContext;
  const email = localStorage.getItem('email');

  const [state, setState] = useState({
    listElem: 5,
  });

  const checkedTerms = (e) => {
    if (e.target.checked) {
      setTerms([...termsVal, e.target.id]);
    } else {
      const value = termsVal.filter((item) => item !== e.target.id);
      setTerms(value);
    }
  };

  const handleSubmit = (e) => {
    if (termsVal.length === 3) {
      localStorage.setItem('auth', true);
      localStorage.setItem('verified', false);
      history.push('/Confirm');
    } else {
      alert('Please accept the terms and conditions');
    }

    // updateRegister({
    //   email,
    //   terms: { agreed: true },
    // });

    // if (updated) {
    //   localStorage.removeItem("verified");
    //   localStorage.setItem("auth", true);
    //   history.push("/Course");
    // }
    // if (message) {
    //   alert("error");
    // }
  };

  const { listElem } = state;
  const items = terms();

  return (
    <Layout
      style="register flex dash-bgcolor w-full h-full fixed"
      imageURL={address_image}
      imageSize="250"
      imagePosition="90"
    >
      <div className="flex w-full h-full">
        <Sidebar listActive={listElem} liObject={listObject} />
        <div className="flex-col w-full h-full md:mr-16 overflow-scroll	">
          <div className="text-center pt-20">
            <Title
              customStyle="font-medium md:text-4xl text-xl p-4 title-color"
              value="Read and agree with our terms and conditions"
            />
            <Subtitles
              customStyle="text-xs p-4 pt-2 sub-color"
              value="Please read these terms and conditions and acknowledge the same to complete registration process."
            />
            <div className="mt-4 overflow-scroll">
              {items.map((term, index) => (
                <div
                  className="flex md:w-1/3 w-2/3 m-auto text-justify px-4 py-2 relative items-start"
                  key={index}
                >
                  <div class="flex items-center h-5">
                    <input
                      type="checkbox"
                      className="form-checkbox mt-2 h-4 w-4 transition duration-150 ease-in-out"
                      id={term.id}
                      value={term.id}
                      onChange={(e) => checkedTerms(e)}
                    />
                  </div>
                  <div class="ml-3 text-sm leading-5">
                    <label htmlFor={term.id} className="text-xs">
                      {term.content}
                    </label>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="btn-fl hover:btn-hv text-white font-medium py-2 p-24 mt-8 rounded-full"
              type="submit"
              onClick={handleSubmit}
            >
              Get Started
            </button>
            <div className="py-4">
              <Subtitles
                customStyle="text-xs pl-4 sub-color"
                value="or press ENTER↵"
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(Legal);
