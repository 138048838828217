import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import './BecomeMember.scss'

function BecomeMember({ history }) {
    return (
        <Grid xs={10} className='member__grid'>
            <Grid xs={12} className='text__grid'>
                <Typography className='description'>
                    Browse our programs or get access to the course <br /> starting from just $89 per program with ISSAcademy certifications.
                </Typography>
            </Grid>
            <Grid xs={12} className='btn__grid'>
                {!localStorage.getItem('token') && (
                    <Button href='/Register' variant="contained" className='member__btn'>Become a member</Button>
                )}
            </Grid>
        </Grid>
    )
}

export default BecomeMember;