import React, { useState, useEffect, useRef, useContext } from 'react';
import './NewPayment.scss';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { Box, Button, Divider, Input } from '@mui/material';
import security from '../../assets/img/security4.jpg';
import Navbar from '../../components/Elements/Navbar/Navbar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AuthContext from '../../context/auth/authContext';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Footer from '../../components/Elements/Footer/Footer';


const Img = styled('img')({
  margin: 'auto',
  marginTop: '5px',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

function NewPayment({ history, match }) {
  const authContext = useContext(AuthContext);
  const [position, setPoisition] = useState(0);
  const [enableCoupon, setEnableCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponBtn, setCouponBtn] = useState('');
  const [paymentModel, setPaymentModel] = useState({});
  const [btnClick, setBtnClick] = useState(false);
  const [stripeDetails, setStripeDetails] = useState({});
  const [cardError, setCardError] = useState({});
  const {
    enrollDetails,
    getInvoiceList,
    invoiceList,
    coursePayment,
    paid,
    initiatePayment,
    stripesData,
  } = authContext;
  const stripes = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (enrollDetails === null) {
      getInvoiceList();
    } else {
      setPaymentModel({
        paymentModel: enrollDetails.invoice._id,
        amount: enrollDetails.invoice.amount,
      });
    }
  }, [enrollDetails]);

  useEffect(() => {
    if (invoiceList.length) {
      invoiceList.map((e) => {
        if (e.courses.includes(match.params.id)) {
          setPaymentModel({ paymentModel: e._id, amount: e.amount });
          initiatePayment(e._id);
        }
      });
    }
  }, [invoiceList]);

  useEffect(() => {
    if (paid !== null) {
      setBtnClick(false);
      if (!paid) alert('Invalid coupon code');
      else if (paid) {
        history.push('/enroll/confirm/60ac8f7e9276872889ed83e3');
      }
    }
  }, [paid]);

  const subscribeCourse = async () => {
    setBtnClick(true);  
    console.log('!cardError.complete', !cardError.complete); 
    console.log('cardError.error && cardError.error.message', cardError.error && cardError.error.message);
    
    if (couponCode) {
      coursePayment(paymentModel.paymentModel, paymentModel.couponCode);
    }  else if (!cardError.complete){
        if(cardError.error && cardError.error.message) {
          alert(cardError.error.message);
        }
    } else {
      const payload = await stripes.confirmCardPayment(
        stripeDetails.reference,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
      );
      console.log('payload:', payload);
      if (payload && payload.paymentIntent && payload.paymentIntent.status === 'succeeded') {
        history.push('/enroll/confirm/60ac8f7e9276872889ed83e3');
      } else {
        // handle error
        console.error('Error: Payment failed');
      }
      setBtnClick(false);
    }
  };


  useEffect(() => {
    if (stripesData !== null) setStripeDetails(stripesData);
  }, [stripesData]);

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
    setCouponBtn(false);
    console.log(couponCode, 'codeee');
  };

  const applyCouponCode = (event) => {
    console.log('Coupon Applied!');
    setCouponBtn(true);
  };

  const handleCardChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    console.log(event);
    setCardError(event);
    // setDisabled(event.empty);
    // setError(event.error ? event.error.message : '');
  };

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (

    <Box className='payment__box'>

      <Navbar course={true} faq={true} history={history}/>
      <Paper
        className='paper'
        sx={{
          p: 5,
          margin: 'auto',
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt="complex" src={security} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom className='course__title' variant="h6" component="div">
                  Certified Security Guard (CSG)
                </Typography>
                <Typography variant="body2" className='course__desc' gutterBottom>
                  Learn the key ideas behind becoming a certified Security Guard, with this disruptive online micro-learning course – no previous experience required!
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                ID: 1030114
              </Typography> */}
              </Grid>
              <Grid item style={{ paddingTop: '0' }}>
                <Typography sx={{ cursor: 'pointer' }} variant="h6" style={{ fontWeight: '800' }}>
                  £{parseInt(paymentModel.amount)}.00
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid item>
            <Typography variant="subtitle1" component="div">
              $19.00
            </Typography>
          </Grid> */}
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h6" gutterBottom style={{ fontWeight: '700', marginTop: '30px' }}>
            Payment details
          </Typography>
          <Divider />
        </Grid>
        <Grid item style={{ marginTop: '2pc' }}>
          <Typography variant="body" gutterBottom>
            Have a coupon code
          </Typography>
        </Grid>
        <Grid container xs={12} style={{ marginTop: '10px' }}>
          <Grid item xs={9} style={{ marginRight: '1pc' }}>
            <Input
              className='coupon__box'
              placeholder='Enter coupon code here...'
              id="input-with-icon-adornment"
              inputProps={{
                style: { color: '#777E90', paddingLeft: '20px' },
                required: true
              }}
              disableUnderline={true}
              value={couponCode}
              onChange={handleCouponCodeChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Button disabled={!couponCode || couponBtn} onClick={applyCouponCode} variant='contained' className='coupon__btn'>Apply</Button>
          </Grid>
          {couponBtn && (
            <Typography variant="subtitle1" gutterBottom className='coupon__msg'>
            Coupon applied successfully!
          </Typography>
            )}
        </Grid>
        <Grid item>
          <Typography variant="h6" gutterBottom style={{ marginTop: '30px', fontWeight: '600' }}>
            Payment with:
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel checked value="card" control={<Radio className='radio__color' />} label="Card" />
          </RadioGroup>
        </Grid>

        {/* <Grid item style={{ marginTop: '2pc' }}>
          <Typography className='input__title' variant="body" gutterBottom>
            Name on card
          </Typography>
          <Input
            className='input__box'
            placeholder='Name on card'
            id="input-with-icon-adornment"
            inputProps={{ style: { color: '#777E90', paddingLeft: '20px' } }}
            disableUnderline={true}
          />
        </Grid> */}
        <Grid className='card__input'>
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleCardChange}
          />
        </Grid>

        <Grid item style={{ marginTop: '20px' }}>
          <Button onClick={() => subscribeCourse()} variant='contained' className='pay__btn'>Pay ${parseInt(paymentModel.amount)}.00</Button>
        </Grid>

        <Grid item style={{ marginTop: '20px', textAlign: 'center', fontSize: '14px', opacity: '0.7' }}>
          <Typography variant='subtitle'>
            Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.
          </Typography>
        </Grid>
      </Paper>

    </Box>
  )
}

export default NewPayment;
