const qualifications = () => [
  {
    name: "Qualification 1",
  },
  {
    name: "Qualification 2",
  },
  {
    name: "Qualification 3",
  },
  {
    name: "Qualification 4",
  },
];

export default qualifications;
