import React, { useState, useEffect, useContext } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { withRouter, useParams } from 'react-router-dom';
import moment from 'moment';
import Navbar from '../../components/Fields/Navbar/Navbar';
import Module from '../../components/Course/Module';
import courseModule from '../../data/courses';
import address_image from '../../assets/images/address.png';
import courseModuleCopy from '../../data/courseCopy';
import AuthContext from '../../context/auth/authContext';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Elements/Layout/Layout';
import BookExam from '../../components/Course/BookExam';
import clientAxios from '../../config/axios';
import useWindowDimensions from '../../components/useWindowDimensions';

//const urlBackEnd = 'https://vendor.issacademy.com/api/v1/';
const urlBackEnd = process.env.REACT_APP_BACKEND_URL;

export const Course = (props) => {
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const [paymentPending, setPaymentPending] = useState(null);
  const [bookingExam, setBookingExam] = useState(false);
  const [courseModules, setCourseModules] = useState([]);
  //const [currentVideo, setCurrentVideo] = useState('');
  const [currentVideo, setCurrentVideo] = useState('951417516');
  const [activeModule, setActiveModule] = useState({});
  const [videoProgress, setVideoProgress] = useState([]);
  const [selectedModule, setSelectedModule] = useState(0);
  const [activeLecture, setActiveLecture] = useState({});
  const [emailSent, setEmailSent] = useState(false);
  const [startFrom, setStartFrom] = useState(0);
  const [overviewIsOpen, setOverview] = useState(false);
  const [slideOpen, setSlideOpen] = useState(null);
  const [notebookIsOpen, setNotebook] = useState(false);
  const [transcriptIsOpen, setTranscript] = useState(true);
  const [enableAllClick, setEnableAllClick] = useState(false);
  const [clickPrevious, setClickPrevious] = useState(false);
  const [examData, setExamData] = useState({});
  const [enableExam, setEnableExam] = useState(false);
  const [duration, setDuration] = useState(0);
  const [sloatDetails, setScheduleDetails] = useState(null);
  const [sendQuestion, setsendQuestion] = useState(false);
  const [examStatus, setExamStatus] = useState({});

  const authContext = useContext(AuthContext);
  const {
    fetchUser,
    user,
    paymentRequest,
    sendExamSchedule,
    fetchExams,
    fetchModule,
    moduleLectureList,
    updateProgress,
    updateCourseStatus,
  } = authContext;

  const [scrolled, setScrolled] = useState(false);
  let courseMain = React.createRef();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 800) {
      setSlideOpen(false);
    } else setSlideOpen(null);
  }, [width]);

  useEffect(() => {
    fetchUser();
    fetchModule(id);
    getExamDetails();
    fetchExams(id);
  }, [id]);

  useEffect(() => {
    if (user) {
      if (user.paymentStatus === 'pending') {
        setPaymentPending(true);
      } else {
        setPaymentPending(false);
        setEmailSent(false);
      }
    }
  }, [user]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (!locationKeys[1] === location.key) {
          setLocationKeys((keys) => [location.key, ...keys]);
          history.push('/');
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    if (moduleLectureList.length) {
      setCourseModules(moduleLectureList);
      setStartFrom(0);
      if (user && user.enrollment.length) {
        const progress = user.enrollment.filter((e) => e.course === id);
        if (progress[0].progress.length) {
          setExamStatus({
            length: progress[0].progress.length,
            progress: progress[0].progress,
          });
          if (progress[0].isComplete) {
            setEnableAllClick(true);
          }
          if (progress[0].progress[0].module) {
            const moduleList = moduleLectureList.filter(
              (e) => e.module === progress[0].progress[0].module
            );
            const lecture =
              moduleList.length &&
              moduleList[0].lectures.filter(
                (e) => e._id === progress[0].progress[0].lecture
              );
            if (moduleList.length !== 0 && lecture.length !== 0) {
              if (progress[0].progress[0].status === 'progress') {
                const progresList = {
                  ...progress[0].progress[0],
                  moduleSequence: moduleList[0].sequence,
                  lectureSequence: lecture[0].sequence,
                };
                setCurrentVideo(lecture[0].video.id);
                setVideoProgress(progresList);
                setSelectedModule(moduleList[0].sequence);
                setActiveModule({
                  moduleid: moduleList[0]._id,
                  lectureId: lecture[0]._id,
                });
                setActiveLecture(lecture[0]);
                setStartFrom(progress[0].progress[0].videoPlaybackLength);
              } else if (progress[0].progress[0].status === 'complete') {
                const nextModule = moduleLectureList.filter(
                  (e) => e.sequence == moduleList[0].sequence + 1
                );
                const nextLecture = moduleList[0].lectures.filter(
                  (e) => e.sequence == lecture[0].sequence + 1
                );
                if (nextModule.length) {
                  if (nextLecture.length) {
                    const progresList = {
                      ...progress[0].progress[0],
                      moduleSequence: moduleList[0].sequence,
                      lectureSequence: nextLecture[0].sequence,
                    };
                    setCurrentVideo(nextLecture[0].video.id);
                    setVideoProgress(progresList);
                    setSelectedModule(moduleList[0].sequence);
                    setActiveModule({
                      moduleid: moduleList[0]._id,
                      lectureId: nextLecture[0]._id,
                    });
                    setActiveLecture(nextLecture[0]);
                  } else {
                    if (
                      nextModule &&
                      nextModule[0].lectures &&
                      nextModule[0].name !== 'Final Exam'
                    ) {
                      const progresList = {
                        ...progress[0].progress[0],
                        moduleSequence: nextModule[0].sequence,
                        lectureSequence: nextModule[0].lectures[0].sequence,
                      };
                      setCurrentVideo(nextModule[0].lectures[0].video.id);
                      setVideoProgress(progresList);
                      setSelectedModule(nextModule[0].sequence);
                      setActiveModule({
                        moduleid: nextModule[0]._id,
                        lectureId: nextModule[0].lectures[0]._id,
                      });
                      setActiveLecture(nextModule[0].lectures[0]);
                    } else {
                      const progresList = {
                        ...progress[0].progress[0],
                        moduleSequence: moduleList[0].sequence,
                        lectureSequence: lecture[0].sequence,
                      };
                      setCurrentVideo(lecture[0].video.id);
                      setVideoProgress(progresList);
                      setSelectedModule(moduleList[0].sequence);
                      setActiveModule({
                        moduleid: moduleList[0]._id,
                        lectureId: lecture[0]._id,
                      });
                      setEnableAllClick(true);
                      updateCourseStatus(id);
                      setActiveLecture(lecture[0]);
                      setBookingExam(true);
                    }
                  }
                }
                //  else {
                //   const progresList = {
                //     ...progress[0].progress[0],
                //     moduleSequence: moduleList[0].sequence,
                //     lectureSequence: lecture[0].sequence,
                //   };
                //   setCurrentVideo(lecture[0].video.id);
                //   setVideoProgress(progresList);
                //   setSelectedModule(moduleList[0].sequence);
                //   setActiveModule({
                //     moduleid: moduleList[0]._id,
                //     lectureId: lecture[0]._id,
                //   });
                //   setEnableAllClick(true);
                //   setActiveLecture(lecture[0]);
                //   setBookingExam(true);
                // }
              }
            } else {
              setCurrentVideo(moduleLectureList[0].lectures[0].video.id);
              setActiveLecture(moduleLectureList[0].lectures[0]);
              setVideoProgress({
                moduleSequence: 0,
                lectureSequence: 0,
              });
              setActiveModule({
                moduleid: moduleLectureList[0]._id,
                lectureId: moduleLectureList[0].lectures[0]._id,
              });
              setSelectedModule(0);
            }
          } else {
            setCurrentVideo(moduleLectureList[0].lectures[0].video.id);
            setActiveLecture(moduleLectureList[0].lectures[0]);
            setVideoProgress({
              moduleSequence: 0,
              lectureSequence: 0,
            });
            setActiveModule({
              moduleid: moduleLectureList[0]._id,
              lectureId: moduleLectureList[0].lectures[0]._id,
            });
            setSelectedModule(0);
          }
        } else {
          setCurrentVideo(moduleLectureList[0].lectures[0].video.id);
          setActiveLecture(moduleLectureList[0].lectures[0]);
          setVideoProgress({
            moduleSequence: 0,
            lectureSequence: 0,
          });
          setActiveModule({
            moduleid: moduleLectureList[0]._id,
            lectureId: moduleLectureList[0].lectures[0]._id,
          });
          setSelectedModule(0);
        }
      } else {
        setCurrentVideo(moduleLectureList[0].lectures[0].video.id);
        setActiveLecture(moduleLectureList[0].lectures[0]);
        setVideoProgress({
          moduleSequence: 0,
          lectureSequence: 0,
        });
        setActiveModule({
          moduleid: moduleLectureList[0]._id,
          lectureId: moduleLectureList[0].lectures[0]._id,
        });
        setSelectedModule(0);
      }
    }
  }, [moduleLectureList, user]);

  const paymentEmail = () => {
    paymentRequest();
    setTimeout(() => {
      setEmailSent(true);
    }, 2000);
  };

  const handleScroll = (e) => {
    const scrollTop = courseMain.current.scrollTop;
    if (scrollTop > 500) {
      setScrolled(true);
    } else setScrolled(false);
  };

  const selectedVideo = (video, lecture) => {
    setCurrentVideo(video);
    setActiveLecture(lecture);
  };

  const updateLectureProgress = (status, length) => {
    console.log(clickPrevious);
    console.log(enableAllClick);
    const data = {
      module: activeModule.moduleid,
      lecture: activeModule.lectureId,
      status,
      videoPlaybackLength: length,
    };
    if (clickPrevious && !enableAllClick) {
      if (status !== 'progress') {
        const moduleList = moduleLectureList.filter(
          (e) => e.module === activeModule.moduleid
        );
        const lecture =
          moduleList.length &&
          moduleList[0].lectures.filter(
            (e) => e._id === activeModule.lectureId
          );
        const nextModule = moduleLectureList.filter(
          (e) => e.sequence == moduleList[0].sequence + 1
        );
        const nextLecture = moduleList[0].lectures.filter(
          (e) => e.sequence == lecture[0].sequence + 1
        );

        if (nextModule.length) {
          if (nextLecture.length) {
            setCurrentVideo(nextLecture[0].video.id);
            setSelectedModule(moduleList[0].sequence);
            setActiveModule({
              moduleid: moduleList[0]._id,
              lectureId: nextLecture[0]._id,
            });
            setActiveLecture(nextLecture[0]);
          } else {
            setCurrentVideo(nextModule[0].lectures[0].video.id);
            setSelectedModule(nextModule[0].sequence);
            setActiveModule({
              moduleid: nextModule[0]._id,
              lectureId: nextModule[0].lectures[0]._id,
            });
            setActiveLecture(nextModule[0].lectures[0]);
          }
        }
      }
    } else if (clickPrevious && enableAllClick) {
      updateProgress(id, [data]);
      if (status !== 'progress') {
        setTimeout(() => {
          fetchUser();
        }, 1000);
      }
    } else if (!clickPrevious && status === 'progress') {
      updateProgress(id, [data]);
    } else if (status !== 'progress') {
      console.log('completed');
      updateProgress(id, [data]);
      setTimeout(() => {
        fetchUser();
      }, 1000);
    }
  };

  const getExamDetails = async () => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(
      urlBackEnd + 'course/' + id + '/exam',
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200 && response.data.length) {
      setExamData(response.data[0].exams[0]);
      userAppointments(response.data[0].exams[0]);
    }
  };

  const userAppointments = async (exam) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(urlBackEnd + 'appointment', {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200 && response.data.length) {
      const dateFormat = moment().format('YYYY-MM-DD');
      const slots = exam.slots.map((e) => e._id);
      const lastSlot = response.data[response.data.length - 1];
      if (slots.includes(lastSlot.slot)) {
        const slotDetails = exam.slots.filter((x) => x._id === lastSlot.slot);
        localStorage.setItem('slot', slotDetails[0].timestamp);
        if (
          dateFormat ===
          moment.utc(slotDetails[0].timestamp).local().format('YYYY-MM-DD')
        ) {
          setBookingExam(false);
          const currentTime = moment();
          const examStartTime = moment.utc(slotDetails[0].timestamp).local();
          const examDuration = moment.duration(exam.duration, moment.ISO_8601);
          const examEndTime = moment(examStartTime).add(
            examDuration,
            'seconds'
          );
          const currentDuration = moment.duration(
            examStartTime.diff(currentTime)
          );
          const endDuration = moment.duration(examEndTime.diff(currentTime));
          if (
            parseInt(currentDuration.asSeconds()) <= 3600 &&
            parseInt(currentDuration.asSeconds()) >= 1
          ) {
            setDuration(parseInt(currentDuration.asSeconds()));
            setScheduleDetails(
              'Your exam begings in ?' + parseInt(currentDuration.asSeconds())
            );
          } else if (
            currentDuration.asSeconds() <= 1 &&
            endDuration.asSeconds() > 1
          ) {
            setDuration(0);
            setEnableExam(true);
            setScheduleDetails(
              'Your exam started at ?' +
                moment(examStartTime).format('YYYY-MM-DD hh:mm A')
            );
          } else {
            setDuration(0);
            setEnableExam(false);
            if (
              moment.utc(slotDetails[0].timestamp).local().isAfter(moment())
            ) {
              setScheduleDetails(
                'Your exam is scheduled on ?' +
                  moment
                    .utc(slotDetails[0].timestamp)
                    .local()
                    .format('YYYY-MM-DD hh:mm A')
              );
            }
          }
        } else {
          if (moment.utc(slotDetails[0].timestamp).local().isAfter(moment())) {
            setScheduleDetails(
              'Your exam is scheduled on ?' +
                moment
                  .utc(slotDetails[0].timestamp)
                  .local()
                  .format('YYYY-MM-DD hh:mm A')
            );
          }
        }
      }
    }
  };
  const activeCourse = currentVideo;
  const moduleName = moduleLectureList.length
    ? moduleLectureList[selectedModule].name
    : '';
  if (paymentPending === null) return <h1>Loading</h1>;
  return (
    <div className="w-full flex flex-col min-h-screen h-screen relative">
      {paymentPending ? (
        <div className="flex w-full">
          <Layout
            style="register flex dash-bgcolor w-full h-full fixed"
            imageURL={address_image}
            imageSize="250"
            imagePosition="90"
          >
            <div className="flex w-full">
              <div className="flex-col w-full flex justify-center text-center items-center">
                <h4 className="text-2xl">Course Activation!</h4>
                <p className="w-1/2 text-xs">
                  Your course is inactive, kindly contact <strong>ISSA</strong>{' '}
                  vendor.
                </p>
                <button
                  className="btn-fl  text-white bg-gray-800 mt-4 py-2 p-5 rounded-full"
                  type="submit"
                  onClick={() => paymentEmail()}
                >
                  Sent me more information
                </button>
                <button
                  className="border mt-4 border-gray-500  px-5 py-1 rounded-full text-sm uppercase"
                  type="submit"
                  onClick={() => history.replace('/')}
                >
                  Home
                </button>
                {emailSent && (
                  <p className="w-1/2 text-xs mt-8">
                    Vendor info has been sent via registered email.
                  </p>
                )}
              </div>
            </div>
          </Layout>
        </div>
      ) : (
        <>
          <Navbar
            enableExam={enableExam}
            history={props.history}
            examData={examData}
            duration={duration}
            slideOpen={slideOpen}
            slideStatus={(e) => setSlideOpen(e)}
            changeExamStatus={(status) => setEnableExam(status)}
          />
          <div className="course w-full flex flex-grow md:flex-row flex-col-reverse	 flex-1 items-stretch">
            <div
              className={`${
                slideOpen === null ? 'block' : slideOpen ? 'block ' : 'hidden '
              } course--sidebar side-height flex-initial w-full md:w-3/12 bg-course-sidebar h-full overflow-y-auto`}
            >
              <div className="course--sidebar-title bg-component-sidebar py-6 px-6 border-b-1 border-component-sidebar">
                <p className="text-sm text-white text-opacity-25">Course</p>
                <h2 className="text-md text-white">Certified Security Guard</h2>
              </div>
              {courseModules.map((modules, index) =>
                modules.lectures && modules.name !== 'Final Exam' ? (
                  <Module
                    key={index}
                    sequence={modules.sequence}
                    title={modules.name}
                    list={modules.lectures}
                    selectedVideo={selectedVideo}
                    moduleId={modules._id}
                    setActiveModule={(moduleid, lectureId) => {
                      if (width < 800) setSlideOpen(!slideOpen);
                      setActiveModule({ moduleid, lectureId });
                    }}
                    videoProgress={videoProgress}
                    selectedModule={selectedModule}
                    changeActiveModule={(id) => setSelectedModule(id)}
                    clickPrevious={(status) => setClickPrevious(status)}
                    setBookingExam={(status) => setBookingExam(status)}
                    currentVideo={currentVideo}
                    enableAllClick={enableAllClick}
                  />
                ) : null
              )}
              <a
                style={{
                  background: 'rgb(68, 167, 81)',
                }}
                onClick={() => {
                  if (enableAllClick) setBookingExam(true);
                }}
                className="bg-component-sidebar border-b-1 border-component-sidebar h-12 py-2 pl-6 pr-2 flex items-center transform text-white text-opacity-40 hover:text-opacity-100 hover:opacity-100 hover:cursor-pointer"
              >
                <p className="w-full font-medium text-xs uppercase">
                  Exam Schedule
                </p>
                <div className="flex-shrink-0 bg-none text-sm py-1 px-2"></div>
              </a>
            </div>
            {bookingExam ? (
              <BookExam
                history={history}
                changeStatus={(status) => setBookingExam(status)}
                examData={examData}
                sloatDetails={sloatDetails}
                examStatus={examStatus}
                disableExam={(e) => setEnableExam(e)}
              />
            ) : (
              <div
                className={`${
                  slideOpen === null
                    ? 'block'
                    : !slideOpen
                    ? 'block '
                    : 'hidden '
                } course--main flex-initial w-full md:w-9/12 bg-course-main overflow-y-auto h-full`}
                onScroll={(e) => handleScroll(e)}
                ref={courseMain}
              >
                <div
                  className={
                    'course--scrollable ' +
                    (scrolled ? 'md:w-9/12 absolute w-full' : '')
                  }
                >
                  <div className="flex course--player-container bg-player-container p-6">
                    <div
                      className={
                        'course--player ' +
                        (scrolled
                          ? 'w-0 w-1/3 pt-mini-video'
                          : 'w-full pt-9/16 ')
                      }
                    >
                      <Vimeo
                        video={`${activeCourse}`}
                        width="660"
                        height="240"
                        // id="122963"
                        id="58479"
                        autoplay
                        start={startFrom}
                        onEnd={() => {
                          updateLectureProgress('complete', 0);
                        }}
                        onTimeUpdate={(e) => {
                          let seconds = parseInt(e.seconds) % 10;
                          if (seconds === 0) {
                            updateLectureProgress(
                              'progress',
                              parseInt(e.seconds)
                            );
                          }
                        }}
                      />
                    </div>
                    <div
                      className={`${
                        scrolled ? 'flex flex-col' : 'hidden'
                      } course--player--titleform`}
                    >
                      <h4 className="course__module__title">{moduleName}</h4>
                      <span className="course__module__list--title">
                        {activeLecture.name}
                      </span>
                    </div>
                  </div>

                  <div className="course--nav bg-course-nav">
                    <ul className="flex items-center justify-center">
                      {/*<li>
                  <a
                    onClick={() => {
                      setNotebook(false);
                      setTranscript(false);
                      setOverview(true);
                    }}
                    className={
                      "text-white cursor-pointer font-bold  hover:text-opacity-100 py-4 px-10 text-sm flex " +
                      (overviewIsOpen ? "text-opacity-100" : "text-opacity-60")
                    }
                  >
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className={"mr-2 w-4 h-4 " + (overviewIsOpen ? "opacity-100" : "opacity-50" ) }
                    >
                      <defs />
                      <g fill="#fff" fillRule="evenodd">
                        <path d="M.615625 11.2286H6.57396V6.07235H.615625z" />
                        <path d="M8.06359 12.660983H.615769v5.729167h18.768847V6.07235H8.063782z" />
                        <path d="M.615625 4.64015h18.768846V.343183H.615625V4.64015zm10.427179-2.864583h6.852179v1.432291h-6.852179V1.775567zm-5.958333 0h1.489583v1.432291H5.084471V1.775567zm-2.979167 0h1.489583v1.432291H2.105304V1.775567z" />
                      </g>
                    </svg>
                    Overview
                  </a>
                  </li>*/}
                      <li>
                        <a
                          onClick={() => {
                            setNotebook(false);
                            setTranscript(true);
                            setOverview(false);
                          }}
                          className={
                            'text-white cursor-pointer font-bold  hover:text-opacity-100 py-4 px-10 text-sm flex ' +
                            (transcriptIsOpen
                              ? 'text-opacity-100'
                              : 'text-opacity-60')
                          }
                        >
                          <svg
                            viewBox="0 0 14 19"
                            fill="currentColor"
                            className={
                              'mr-2 w-4 h-4 ' +
                              (transcriptIsOpen ? 'opacity-100' : 'opacity-50')
                            }
                          >
                            <defs />
                            <g fill="#fff" fillRule="evenodd">
                              <path
                                fill="#fff"
                                d="M3.091039 1.063726L.333333 3.600138h2.757706z"
                              />
                              <path
                                d="M4.193274.84349v3.861h-3.861v13.786412h13.235294L13.568603.843491H4.193274zm6.617647 14.33753H3.09104v-1.103294h7.720941l-.001059 1.103294zm0-2.756647H3.09104v-1.103294h7.720941l-.001059 1.103294zm0-2.757706H3.09104V8.563373h7.720941l-.001059 1.103294z"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                          Transcript
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            setNotebook(true);
                            setTranscript(false);
                            setOverview(false);
                          }}
                          className={
                            'text-white cursor-pointer font-bold  hover:text-opacity-100 py-4 px-10 text-sm flex ' +
                            (notebookIsOpen
                              ? 'text-opacity-100'
                              : 'text-opacity-60')
                          }
                        >
                          <svg
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className={
                              'mr-2 w-4 h-4 ' +
                              (notebookIsOpen ? 'opacity-100' : 'opacity-50')
                            }
                          >
                            <defs />
                            <path
                              d="M19.533333 4.280031V2.37335h-1.906656V.466667H2.373323v3.81326H.466667V6.18661h1.906656v7.626782H.466667v1.906682h1.906656v3.81326h15.253354v-7.62678h1.906656V9.99987h-1.906656V8.093188h1.906656V6.186505h-1.906656V4.279823l1.906656.000208zM8.093448 17.626963H4.280239v-1.906682h1.906657V13.8136H4.28024V6.186818h1.906656V4.280135H4.28024V2.373453h3.813208v15.25351z"
                              fill="#fff"
                              fillRule="evenodd"
                            />
                          </svg>
                          Ask the expert
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={
                    'course--content ' + (scrolled ? ' padding-top-700 ' : ' ')
                  }
                >
                  {/* Course Transcript */}
                  {transcriptIsOpen && (
                    <div
                      className="course--transcript pt-14 pb-16"
                      style={{ background: '#E8E8E8' }}
                    >
                      <div className="w-10/12 mx-auto">
                        <div
                          className="mt-14"
                          dangerouslySetInnerHTML={{
                            __html: activeLecture.content,
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                  {/* Course Notebook */}
                  {notebookIsOpen && (
                    <div className="course--notebook pt-14 pb-72">
                      <div className="w-7/12 mx-auto">
                        <div className="mb-6 flex items-end flex-col	">
                          <textarea
                            class="resize border w-full h-44 p-4 rounded-md"
                            placeholder="Type your question here and press enter"
                          ></textarea>
                          <button
                            onClick={() => setsendQuestion(true)}
                            className="mt-4 w-16 bg-gray-800 text-center px-5 py-1 hover:btn-hv text-white mr-5 rounded-full text-sm uppercase outline-none"
                          >
                            Ask
                          </button>
                        </div>
                        {sendQuestion && (
                          <p className="text-sm  text-gray-500 leading-14">
                            Security expert will respond to your questions
                            within the next 24 hours.
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(Course);
