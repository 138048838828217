import React from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
import './WhyISSAcademy.scss';
import logoWhite from '../../assets/img/logo_white.png';
import vector_1 from '../../assets/img/vector-1.png';
import vector_2 from '../../assets/img/vector-2.png';
import Navbar from '../../components/Elements/Navbar/Navbar';
import Footer from '../../components/Elements/Footer/Footer';
import PersonIcon from '@mui/icons-material/Person';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';


function WhyISSAcademy({ history }) {
  return (
    <Box className='issa__box'>

      <Navbar history={history} course={true} faq={true} />

      <Grid container xs={12} className='container__grid'>
        <Grid xs={12} sm={4} md={5} lg={5} item className='item__grid'>
          <Typography variant='h3' className='title__txt'>
            Why shoud you choose ISSAcademy
          </Typography>
          <Typography variant='body2' className='body__txt'>
            Find various courses ranging from Security to Police to Fire Brigade. All in one place!
          </Typography>
          {!localStorage.getItem('token') && (
            <Button href='/Register' className='member__btn' variant='contained'>Become a member</Button>
          )}
        </Grid>
        <Grid xs={0} sm={2} md={1} item>
        </Grid>
        <Grid xs={12} sm={4} md={4} lg={4} item className='img__grid'>
          <img src={logoWhite} />
        </Grid>
      </Grid>


      <Grid container xs={12} className='info__box'>
        <Grid container xs={8} className='card__header__grid'>
          <Typography variant="h1" className="title">
            What we offer
          </Typography>
          <Typography variant="body2" className="paragraph">
            Take your career to the next level. Build essential security skill with ISSAcademy with a curated learning experience.
          </Typography>
        </Grid>
        <Grid container xs={6} className="cardContainer">
          <Grid item>
            <Card className="card">
              <CardContent className='cardContent'>
                <div className="cardIcon" style={{ backgroundColor: '#EBEFFA' }}>
                  <SchoolOutlinedIcon className='icon' />
                </div>
                <Typography align='left' variant="subtitle2" className='cardText'>
                  Strengthen your knowledge of safety and security protocols
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid>
            <Card className="card">
              <CardContent className='cardContent'>
                <div className="cardIcon" style={{ backgroundColor: '#EBEFFA' }}>
                  <LanguageOutlinedIcon className='icon' />
                </div>
                <Typography align='left' variant="subtitle2" className='cardText'>
                  Discover how security is reshaping our world</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid>
            <Card className="card">
              <CardContent className='cardContent'>
                <div className="cardIcon" style={{ backgroundColor: '#EBEFFA' }}>
                  <PersonIcon className='icon' />
                </div>
                <Typography align='left' variant="subtitle2" className='cardText'>
                  Become a strong force that empowers people</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} container className='info__container'>
        <Grid xs={9} sm={8} md={5} className='txt__grid'>
          <Typography variant='h3' className='txt__header'>
            Data-driven course selection
          </Typography>
          <Typography variant='body2' fontSize={20} className='txt__body'>
            Our longstanding expertise in market research, data analysis and business insights ensures that we select the most relevant courses for your future.
          </Typography>
        </Grid>
        <Grid xs={0.5} />
        <Grid xs={9} sm={6} md={4} className='img__grid vector'>
          <img src={vector_1} />
        </Grid>
      </Grid>

      <Grid xs={12} container className='info__container_2'>
        <Grid xs={6} sm={6} md={4} className='img__grid vector'>
          <img src={vector_2} />
        </Grid>
        <Grid xs={2} sm={0.5} md={2} lg={1} />
        <Grid xs={9} sm={6} md={5} className='txt__grid'>
          <Typography variant='h3' className='txt__header'>
            Outcomes-based design
          </Typography>
          <Typography variant='body2' fontSize={20} className='txt__body'>
            Our career-focused curriculum harnesses a range of multimedia formats to optimise learning outcomes. This ensures that you complete a course with university verified skills that are relevant to your industry. Since our inception (2019) we've achieved a 90% completion rate.
          </Typography>
        </Grid>
      </Grid>

      <Grid xs={12} container className='info__container_3'>
        <Grid xs={9} sm={8} md={5} className='txt__grid'>
          <Typography variant='h3' className='txt__header'>
            Data-driven course selection
          </Typography>
          <Typography variant='body2' fontSize={20} className='txt__body'>
            Our longstanding expertise in market research, data analysis and business insights ensures that we select the most relevant courses for your future.
          </Typography>
        </Grid>
        <Grid xs={0.5} />
        <Grid xs={9} sm={6} md={4} className='img__grid vector'>
          <img src={vector_1} />
        </Grid>
      </Grid>
      <Footer />
    </Box>

  )
}

export default WhyISSAcademy;