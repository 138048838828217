import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Payment from './Payment';
import NewPayment from '../NewPayment/NewPayment';

const promise = loadStripe(
  //'pk_test_51IjiQlKqWg7IzF5Yw69dy0fFWxrQKN3KII2Rl7B8k0LQB1g8QfGub5bjWWDvuCL7yp8a1uxpJyFlSBhro72ORLJ600Bkm2SSbD'
 // 'pk_test_51PJVNACZmIqknjUqfE7uvr6OA3zEDBMe76kYoiocJZ3eDTlhVAPV1yvGpoYDtev7HMpNwjZ86taiBumERMoEWmP200Vl63ALPo'
  'pk_live_51PJVNACZmIqknjUqxLqs4ZHxekwoXAiCCgrJcKBFtjLMv9pZ782siJBiL8MPVdtZ9vIEu6kOYyyUdhwdSMWKRoSs00t2PON8iA'
);

export default function PaymentWrapper({ history, match }) {
  return (
    <div className="App">
      <Elements stripe={promise}>
        <NewPayment history={history} match={match} />
        {/* <Payment history={history} match={match} /> */}
      </Elements>
    </div>
  );
}
