import React from 'react';
import PropTypes from 'prop-types';
import DynamicInput from '../../../Fields/DynamicInput/DynamicInput';
import Subtitles from '../../../Fields/Subtitles/Subtitles';

const ConfirmEmail = ({
  handleChange,
  code,
  handleSubmit,
  classVal,
  handleResend,
}) => (
  <>
    <form
      className="flexDirectionCol flex md:mx-64 md:px-16 md:mt-32 mt-12"
      onSubmit={handleSubmit}
    >
      <p className="text-xs font-medium w-full reg-label mb-1">
        VERIFICATION CODE
      </p>
      <DynamicInput
        type="text"
        placeholder=""
        handleChange={handleChange}
        value={code || ''}
        name="code"
        customStyle={classVal}
      />
      <button
        className="btn-fl hover:btn-hv text-white font-medium py-2 p-24  rounded-full"
        type="submit"
        disabled={!code}
      >
        Verify Code
      </button>
      <div className="flex py-4">
        <Subtitles
          customStyle="text-xs pl-4 sub-color"
          value="Didn’t receive code?"
        />
        &nbsp;
        <a href="#" onClick={handleResend} className="text-xs font-bold">
          Resend
        </a>
      </div>
    </form>
  </>
);

ConfirmEmail.propTypes = {
  handleChange: PropTypes.func,
  code: PropTypes.string,
  handleSubmit: PropTypes.func,
  classVal: PropTypes.string,
};

export default ConfirmEmail;
