import React, { useState, useContext, useEffect } from 'react';
import Layout from '../../components/Elements/Layout/Layout';
import DynamicInput from '../../components/Fields/DynamicInput/DynamicInput';
import Subtitles from '../../components/Fields/Subtitles/Subtitles';
import Title from '../../components/Fields/Title/Title';
import AuthContext from '../../context/auth/authContext';
import Loader from '../../components/Fields/Loader/Loader';
import Footer from '../../components/Fields/Footer/Footer';
import personal_info from '../../assets/images/personal_info.png';
import { QUALIFICATION } from '../../data/Qualification';

export const UserProfile = ({ history }) => {
  const authContext = useContext(AuthContext);
  const [userDetails, setuserDetails] = useState({});
  const [update, setUpdate] = useState(null);
  const { fetchUser, user, updateUser } = authContext;
  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    setuserDetails(user);
  }, [user]);

  const handleChange = (e) => {
    setuserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setUpdate(true);
    updateUser(userDetails);
    setTimeout(() => {
      setUpdate(false);
      setTimeout(() => {
        history.push('/Course/details');
      }, 1000);
    }, 2000);
  };
  return (
    <Layout
      style="register flex dash-bgcolor w-full h-full fixed"
      imageURL={personal_info}
      imageSize="250"
      imagePosition="90"
    >
      <div className="flex w-full h-full ">
        <div className="flex-col w-full h-full md:mr-16">
          <div className="text-center md:pt-12 pt-24 mb-2">
            <Title
              customStyle="md:text-4xl text-xl font-medium  title-color"
              value="My Profile"
            />
            <Subtitles
              customStyle="text-xs p-4 pt-0 sub-color"
              value="Update Personal information."
            />
            <form
              className="text-center flex flex-col items-center w-full md:w-2/3 md:mx-64 px-16 mt-4"
              onSubmit={handleSubmit}
            >
              <div className="flex w-full md:w-4/5">
                <div className="w-full">
                  <p className="text-xs font-medium w-full reg-label mb-1 text-left">
                    FIRST NAME
                  </p>
                  <DynamicInput
                    type="text"
                    placeholder=""
                    value={userDetails?.firstName}
                    name="firstName"
                    disabled
                    handleChange={handleChange}
                  />
                </div>
                <div className="w-full ml-6">
                  <p className="text-xs font-medium w-full reg-label mb-1 text-left">
                    LAST NAME
                  </p>
                  <DynamicInput
                    type="text"
                    disabled
                    placeholder=""
                    value={userDetails?.lastName}
                    name="lastName"
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex w-full md:w-4/5">
                <div className="w-full">
                  <p className="text-xs font-medium w-full reg-label mb-1 text-left">
                    EMAIL
                  </p>
                  <DynamicInput
                    type="email"
                    disabled
                    placeholder=""
                    value={userDetails?.email}
                    name="email"
                    handleChange={handleChange}
                  />
                </div>
                <div className="w-full ml-6">
                  <p className="text-xs font-medium w-full reg-label mb-1 text-left">
                    COUNTRY
                  </p>
                  <DynamicInput
                    type="country"
                    placeholder=""
                    value={userDetails?.address?.country}
                    name="country"
                    handleChange={(e) =>
                      setuserDetails({
                        ...userDetails,
                        address: {
                          ...userDetails.address,
                          [e.target.name]: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex w-full md:w-4/5">
                <div className="w-full">
                  <p className="text-xs font-medium w-full reg-label mb-1 text-left">
                    CITY
                  </p>
                  <DynamicInput
                    type="text"
                    placeholder=""
                    value={userDetails?.address?.city}
                    name="city"
                    handleChange={(e) =>
                      setuserDetails({
                        ...userDetails,
                        address: {
                          ...userDetails.address,
                          [e.target.name]: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="w-full ml-6">
                  <p className="text-xs font-medium w-full reg-label mb-1 text-left">
                    STATE
                  </p>
                  <DynamicInput
                    type="text"
                    placeholder=""
                    value={userDetails?.address?.state}
                    name="state"
                    handleChange={(e) =>
                      setuserDetails({
                        ...userDetails,
                        address: {
                          ...userDetails.address,
                          [e.target.name]: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex w-full md:w-4/5">
                <div className="w-full">
                  <p className="text-xs font-medium w-full reg-label mb-1 text-left">
                    ZIP
                  </p>
                  <DynamicInput
                    type="number"
                    placeholder=""
                    value={userDetails?.address?.zip}
                    name="zip"
                    handleChange={(e) =>
                      setuserDetails({
                        ...userDetails,
                        address: {
                          ...userDetails.address,
                          [e.target.name]: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="w-full ml-6">
                  <p className="text-xs font-medium w-full reg-label mb-1 text-left">
                    Mobile
                  </p>
                  <DynamicInput
                    type="text"
                    placeholder=""
                    value={userDetails?.phone}
                    name="phone"
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex w-full md:w-4/5">
                <div className="w-full">
                  <p className="text-xs font-medium w-full reg-label mb-1 text-left">
                    Height In CM
                  </p>
                  <DynamicInput
                    type="text"
                    value={userDetails?.height}
                    handleChange={handleChange}
                    placeholder=""
                    name="height"
                  />
                </div>
                <div className="w-full ml-6">
                  <p className="text-xs font-medium w-full reg-label mb-1 text-left">
                    Qualification
                  </p>
                  <select
                    name="qualification"
                    id="qualification"
                    onChange={handleChange}
                    className="form-select placeholder-gray-500 placeholder-opacity-100 border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5 mx-0"
                  >
                    <option value="">Select Qualification</option>
                    {QUALIFICATION.map((e) => {
                      return <option value={e.name}>{e.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </form>
            <div className="flex justify-center mt-4 ">
              <button
                className="border border-gray-500 px-12 py-2 rounded-full text-sm uppercase"
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              <button
                className="border ml-4  border-gray-500 px-12 py-2 rounded-full text-sm text-white uppercase bg-gray-800"
                onClick={() => handleSubmit()}
              >
                {update ? <Loader /> : 'Update'}
              </button>
            </div>
            {update !== null && !update && (
              <span className="mt-2">
                <i class="fa fa-check-circle  mr-2"></i>Your profile updated
                successfully!
              </span>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default UserProfile;
