import React, { useState } from "react";

import { withRouter } from "react-router-dom";
import Sidebar from "../../../components/Fields/Sidebar/Sidebar";
import Title from "../../../components/Fields/Title/Title";
import Subtitles from "../../../components/Fields/Subtitles/Subtitles";
import { listObject } from "../../../components/Constant";
import ButtonDynamic from "../../../components/Fields/Button/Button";
import idnow from "./../../../assets/images/idnow.png";
import address_image from "../../../assets/images/address.png";
import Layout from "../../../components/Elements/Layout/Layout";

export const Identity = ({ identity, history }) => {
  const [state, setState] = useState({
    listElem: 4,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push("./Legal");
  };

  const { listElem } = state;

  return (
    <Layout  style="register flex dash-bgcolor w-full h-full fixed"
    imageURL={address_image}
    imageSize="250"
    imagePosition="90">
 <div className=" w-full h-full fixed">
      <div className="flex w-full h-full">
        <Sidebar listActive={listElem} liObject={listObject} />
        <div className="flex-col w-full h-full md:mr-16">
          <div className="text-center pt-16">
            <Title
              customStyle="text-4xl p-4 title-color"
              value="Confirm identity"
            />
            <Subtitles
              customStyle="text-xs p-1 sub-color"
              value="Kindly verify your biometric identity. Kindly proceed as per instruction."
            />
          </div>
          <div>
            <img className="object-contain h-3/4 w-1/2 m-auto" src={idnow} />
          </div>
          <div className="text-center">
            <button
              className="btn-fl hover:btn-hv text-white font-bold py-2 p-24 mt-2 rounded-full"
              type="submit"
              onClick={handleSubmit}
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>
  
    </Layout>
   );
};

export default withRouter(Identity);
