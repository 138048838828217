import React, { useState } from 'react';

let EPSILON = 0.000001;
const Module = ({
  moduleId,
  title,
  list,
  sequence,
  changeActiveModule,
  selectedVideo,
  lastVideo,
  setActiveModule,
  selectedModule,
  videoProgress,
  setBookingExam,
  clickPrevious,
  currentVideo,
  enableAllClick,
}) => {
  return (
    <div className="course--sidebar-module">
      <a
        onClick={() => changeActiveModule(sequence)}
        style={{
          background: title === 'Schedule Exam' && 'rgb(68, 167, 81)',
        }}
        className="bg-component-sidebar border-b-1 border-component-sidebar py-2 pl-6 pr-2 flex items-center transform text-white text-opacity-40 hover:text-opacity-100 hover:opacity-100"
      >
        <p className="w-full font-medium text-xs uppercase">
          {title !== 'Schedule Exam' && `${sequence + 1}. `}
          {title}
        </p>
        <div className="flex-shrink-0 bg-none text-sm py-1 px-2">
          {selectedModule === sequence && (
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              className="chevron-up w-6 h-6 fill-current text-white opacity-50"
            >
              <path
                fillRule="evenodd"
                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}

          {selectedModule !== sequence && (
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              className="chevron-down w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </div>
      </a>
      {selectedModule === sequence && (
        <div className="course--sidebar-list border-b-1 border-component-sidebar py-2">
          <ul className="p-0 m-0">
            {list &&
              list.length &&
              list.map((lecture, index) => (
                <li key={index}>
                  <a
                    onClick={() => {
                      if (
                        parseFloat(
                          `${sequence + 1}.${
                            lecture.sequence < 10
                              ? '0' + lecture.sequence
                              : lecture.sequence
                          }`
                        ) <=
                          parseFloat(
                            `${videoProgress.moduleSequence + 1}.${
                              videoProgress.lectureSequence < 10
                                ? '0' + videoProgress.lectureSequence
                                : videoProgress.lectureSequence
                            }`
                          ) ||
                        enableAllClick
                      ) {
                        setBookingExam(false);
                        selectedVideo(lecture.video.id, lecture);
                        setActiveModule(moduleId, lecture._id);
                        if (
                          parseFloat(`${sequence + 1}.${lecture.sequence}`) ===
                          parseFloat(
                            `${videoProgress.moduleSequence + 1}.${
                              videoProgress.lectureSequence
                            }`
                          )
                        ) {
                          clickPrevious(false);
                        } else {
                          clickPrevious(true);
                        }
                      }
                      //   changeCouseKey(course.Key);
                      // selectedVideo(lecture.video.id);
                      //   setActiveModule(moduleId, lecture._id);
                    }}
                    className={
                      'course--course-item cursor-pointer flex text-white text-opacity-60 text-sm py-2 px-12 ' +
                      (parseFloat(
                        `${sequence + 1}.${
                          lecture.sequence < 10
                            ? '0' + lecture.sequence
                            : lecture.sequence
                        }`
                      ) >
                      parseFloat(
                        `${videoProgress.moduleSequence + 1}.${
                          videoProgress.lectureSequence < 10
                            ? '0' + videoProgress.lectureSequence
                            : videoProgress.lectureSequence
                        }`
                      )
                        ? enableAllClick
                          ? 'hover:text-opacity-100 text-opacity-80 tick active'
                          : 'text-opacity-30 lock'
                        : 'hover:text-opacity-100 text-opacity-80 tick active')
                    }
                  >
                    {sequence + 1}.{lecture.sequence + 1}
                    <span
                      className={`${
                        currentVideo === lecture.video.id && 'yellow'
                      } ml-2`}
                    >
                      {lecture.name}
                    </span>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Module;
