import React from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
import './AboutUs.scss';
import gunman from '../../assets/img/gunman.png';
import fire from '../../assets/img/Image (5).png';
import safety from '../../assets/img/Image (4).png';
import img from '../../assets/img/Image (3).png';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Navbar from '../../components/Elements/Navbar/Navbar';
import Footer from '../../components/Elements/Footer/Footer';

import SecurityIcon from '@mui/icons-material/Security';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';

const images = [gunman, fire, safety, img, gunman, fire, safety, img];

function AboutUs({ history }) {
    return (
        <Box className='about__us'>

            <Navbar history={history} course={true} faq={true} />
            <Grid container xs={12} className='container__grid'>
                <Grid xs={10} sm={10} md={8} lg={6} item className='item__grid'>
                    <Typography variant='h3' className='title__txt'>
                        About ISSAcademy
                    </Typography>
                    <Typography variant='body2' className='body__txt'>
                        We all share a passion for changing the way people think about security, for the better. This combined with our talented team make ISSAcademy what it is today.
                    </Typography>
                </Grid>

            </Grid>

            <div className="scrolling-wrapper">
                {images.map((image, index) => (
                    <Card className="card">
                        <CardMedia
                            component="img"
                            image={image}
                        />
                    </Card>
                ))}
            </div>

            <Grid container xs={12} className='mission__grid'>
                <Grid xs={10} sm={10} md={8} lg={6} item style={{textAlign: 'center'}} className='item__grid'>
                    <Typography variant='subtitle1' className='title__txt'>
                        OUR MISSION
                    </Typography>
                    <Typography variant='h6' className='body__txt'>
                        Intelligently consolidating data through the use of technology to help create potential course and allow you to make better decisions.
                    </Typography>
                </Grid>
            </Grid>


            <Grid container xs={12} className='card__grid'>
                <Grid container xs={12} md={8} item className='item__box'>
                    <Grid xs={12} sm={5} md={5} lg={5} item className='item item__1'>
                        <div className='img__box'>
                            {/* <img src={personalcard} /> */}
                            <SecurityIcon className='icon'/>
                        </div>
                        <div className='item__txt__box'>
                            <Typography variant='h6' className='title'>
                                1,395+
                            </Typography>
                            <Typography variant='caption' className='caption__txt'>
                                Security personnel got certified
                            </Typography>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={5} md={5} lg={5} item className='item item__2'>
                        <div className='img__box'>
                            {/* <img src={activity} /> */}
                            <SchoolOutlinedIcon className='icon'/>
                        </div>
                        <div className='item__txt__box'>
                            <Typography variant='h6' className='title'>
                                02
                            </Typography>
                            <Typography variant='caption' className='caption__txt'>
                                New courses every 6 months
                            </Typography>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={5} md={5} lg={5} item className='item item__3'>
                        <div className='img__box'>
                            {/* <img src={magic_star} /> */}
                            <WorkOutlineIcon className='icon'/>
                        </div>
                        <div className='item__txt__box'>
                            <Typography variant='h6' className='title'>
                                600+
                            </Typography>
                            <Typography variant='caption' className='caption__txt'>
                                Jobs created
                            </Typography>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={5} md={5} lg={5} item className='item item__4'>
                        <div className='img__box'>
                            {/* <img src={monitor_recorder} /> */}
                            <ModelTrainingOutlinedIcon className='icon'/>
                        </div>
                        <div className='item__txt__box'>
                            <Typography variant='h6' className='title'>
                                3,255+
                            </Typography>
                            <Typography variant='caption' className='caption__txt'>
                            Guards under training
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>


            <Grid container xs={12} className='mission__grid'>
                <Grid xs={10} sm={10} md={8} lg={6} item style={{textAlign: 'center'}} className='item__grid'>
                    <Typography variant='subtitle1' className='title__txt'>
                        OUR STORY
                    </Typography>
                    <Typography variant='h6' className='body__txt'>
                        See how we started ISSAcademy, a timeline for you...
                    </Typography>
                </Grid>
            </Grid>

            <Grid container xs={12} className='timeline__container'>
                <Grid container xs={12} sm={9} md={8} lg={8} item className='timeline__grid'>
                    <Timeline>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot className='timeline__dot' variant="outlined" />
                                <TimelineConnector className='timeline__connecter' style={{ background: '#28d79c' }} />
                            </ TimelineSeparator >
                            <TimelineContent className='timeline__content'>
                                <Typography className='timeline__year' variant="h6" component="span">2019</Typography>
                                <Typography className='timeline__body__txt'>ISSAcademy was founded in London, United Kindom.</Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot className='timeline__dot' variant="outlined" />
                                <TimelineConnector className='timeline__connecter' style={{ background: '#28d79c' }} />
                            </ TimelineSeparator >
                            <TimelineContent className='timeline__content'>
                                <Typography className='timeline__year' variant="h6" component="span">2020</Typography>
                                {/* <Typography className='timeline__body__txt'>The first security course was launched. About 5k students enrolled which was a start to something great.</Typography> */}
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot className='timeline__dot' variant="outlined" />
                                <TimelineConnector className='timeline__connecter' style={{ background: '#28d79c' }} />
                            </ TimelineSeparator >
                            <TimelineContent className='timeline__content'>
                                <Typography className='timeline__year' variant="h6" component="span">2021</Typography>
                                {/* <Typography className='timeline__body__txt'>The first security course was launched. About 5k students enrolled which was a start to something great.</Typography> */}
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot className='timeline__dot border__dotted' variant="outlined" />
                            </ TimelineSeparator >
                            <TimelineContent className='timeline__content'>
                                <Typography className='timeline__year' variant="h6" component="span">2022</Typography>
                                <Typography className='timeline__body__txt'>The first security course was launched. About 5k students enrolled which was a start to something great.</Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>

                </Grid>
            </Grid>

            <Grid container xs={12} className='btn__container'>
                <Button href='/courses' className='explore__btn' variant='contained'>Explore courses</Button>
            </Grid>
            <Footer />
        </Box>

    )
}

export default AboutUs;