import React from 'react';
import moment from 'moment';

export default function TimePicker({ onSelect, selectedTime, slots }) {
  return (
    <div className="time-picker">
      {slots.map((e) => {
        return (
          <div
            onClick={() => onSelect(e.slot)}
            key={e.slot}
            className={
              selectedTime === e.slot
                ? 'shadow-sm border mb-3 p-2 mr-5 rounded-full text-sm px-5 cursor-pointer hover:bg-cool-gray-300 selected-time'
                : 'shadow-sm border mb-3 p-2 mr-5 rounded-full text-sm px-5 cursor-pointer hover:bg-cool-gray-300'
            }
          >
            {e.time}
          </div>
        );
      })}
    </div>
  );
}
