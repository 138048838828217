import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import {
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOG_OUT,
  VERIFICATION_SUCCESS,
  VERIFICATION_ERROR,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  PASS_SUCCESS,
  PASS_ERROR,
  ADDRESS_SUCCESS,
  ADDRESS_ERROR,
  USER_SUCCESS,
  USER_ERROR,
  COURSE_SUCCESS,
  COURSE_ERROR,
  LECTURE_ERROR,
  LECTURE_SUCCESS,
  EXAM_SUCCESS,
  EXAM_ERROR,
  QUESTION_SUCCESS,
  QUESTION_ERROR,
  ENROLL_SUCCESS,
  ENROLL_ERROR,
  INVOICE_SUCCESS,
  INVOICE_ERROR,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  LISTQUESTIONS,
  LISTQUESTIONS_ERROR,
  CERTIFICATE_SUCCESS,
  CERTIFICATE_ERROR,
  ATTEMPT_SUCCESS,
  ATTEMPT_ERROR,
  STRIPE_DETAILS_SUCCESS,
  STRIPE_DETAILS_ERROR,
} from '../../types';

import clientAxios from '../../config/axios';

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    authenticated: null,
    verified: null,
    registered: null,
    passwordSet: null,
    addressSet: null,
    updated: null,
    email: null,
    code: null,
    message: null,
    user: null,
    courseList: [],
    moduleLectureList: [],
    examList: [],
    questionList: [],
    startTime: null,
    enrollDetails: null,
    invoiceList: [],
    paid: null,
    certificate: null,
    userDetails: null,
    attemptUser: [],
    stripesData: null,
  };

  //const urlBackEnd = 'https://vendor.issacademy.com/api/v1/';
  const urlBackEnd = process.env.REACT_APP_BACKEND_URL;

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const registerUser = async (data) => {
    try {
      const response = await clientAxios.post(urlBackEnd + 'customer', data);
      if (response.code) {
        dispatch({
          type: REGISTER_ERROR,
          payload: data.email,
        });
      } else {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: data.email,
          verified: false,
        });
      }
    } catch (error) {
      if (error.toString().includes('400')) {
        dispatch({
          type: REGISTER_ERROR,
          registered: true,
          payload: null,
        });
      } else {
        dispatch({
          type: REGISTER_ERROR,
          registered: true,
          payload: null,
        });
      }
    }
  };

  const loginUser = (data) => { 
    clientAxios
      .post(urlBackEnd + 'user/login', data)
      .then((response) => {
        if (response.data) {
          dispatch(loginValidation(response.data));
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: response.data.message,
          });
        }
      })
      .catch((error) => {
        if (error.toString().includes('401')) {
          dispatch({
            type: LOGIN_ERROR,
            payload: 'Email or Password is Incorrect',
          });
        } else if (error.toString().includes('403')) {
          dispatch({
            type: LOGIN_ERROR,
            payload: 'User email is not verified',
          });
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload:
              'Maximum number of login attempts exceeded. Please try again later',
          });
        }
      });
  };

  const loginValidation = async (token) => {
    const response = await clientAxios.get(urlBackEnd + 'user', {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      if (response.data.type !== 'vendor') {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: token,
        });
      } else {
        dispatch({
          type: LOGIN_ERROR,
          payload: 'Email or Password is Incorrect',
        });
      }
    }
  };

  const fetchUser = async () => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(urlBackEnd + 'user', {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      dispatch({
        type: USER_SUCCESS,
        user: response.data,
      });
    } else {
      dispatch({
        type: USER_ERROR,
      });
    }
  };

  const logOut = async () => {
    dispatch({
      type: LOG_OUT,
    });
  };

  const forgotPassword = async (email) => {
    const response = await clientAxios.post(
      urlBackEnd + 'user/forgotpassword',
      email
    );

    return response;
  };

  const verifyCode = async (data) => {
    try {
      const response = await clientAxios.patch(
        urlBackEnd + 'customer/verify',
        data
      );
      if (response.data) {
        localStorage.setItem('token', response.data);
        dispatch({
          type: VERIFICATION_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: VERIFICATION_ERROR,
          payload: 'Incorrect Code',
        });
      }
    } catch (error) {
      dispatch({
        type: VERIFICATION_ERROR,
        payload: 'Error',
      });
    }
  };

  const resendEmail = async (data) => {
    const response = await clientAxios.put(
      urlBackEnd + 'customer/resend',
      data
    );
  };

  const setPass = async (data) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.patch(urlBackEnd + 'user', data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      dispatch({
        type: PASS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: PASS_ERROR,
        payload: response.data,
      });
    }
  };

  const updateRegister = async (data) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.patch(urlBackEnd + 'user', data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.data) {
      dispatch({
        type: UPDATE_SUCCESS,
        payload: response.data.token,
      });
    } else {
      dispatch({
        type: UPDATE_ERROR,
        payload: response.data.message,
      });
    }
  };

  const setAddress = async (data) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.patch(urlBackEnd + 'user', data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.data) {
      dispatch({
        type: ADDRESS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: ADDRESS_ERROR,
        payload: response.data.message,
      });
    }
  };

  const paymentRequest = async () => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.put(
      urlBackEnd + 'payment/request',
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response);
  };

  const sendExamSchedule = async (data) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.post(urlBackEnd + 'appointment', data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response);
  };

  const enrollCourse = async (courseId) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.put(
      urlBackEnd + `course/${courseId}/enroll`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch({
        type: ENROLL_SUCCESS,
        enrollDetails: response.data,
      });
    } else {
      dispatch({
        type: ENROLL_ERROR,
      });
    }
  };

  const fetchCourse = async () => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(urlBackEnd + 'course', {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      dispatch({
        type: COURSE_SUCCESS,
        courseList: response.data,
      });
    } else {
      dispatch({
        type: COURSE_ERROR,
      });
    }
  };

  const fetchModule = async (courseId) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(
      urlBackEnd + `course/${courseId}/module`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      fetchLecture(response.data, courseId);
    }
  };

  const fetchLecture = async (modules, courseId) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(
      urlBackEnd + `course/${courseId}/lecture`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      if (response.status === 200) {
        dispatch({
          type: LECTURE_SUCCESS,
          lectureList: response.data,
          moduleList: modules,
        });
      } else {
        dispatch({
          type: LECTURE_ERROR,
        });
      }
    }
  };

  const updateProgress = (courseId, data) => {
    const token = localStorage.getItem('token');
    clientAxios.put(urlBackEnd + `course/${courseId}/progress`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const fetchExams = async (courseId) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(
      urlBackEnd + `course/${courseId}/exam`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch({
        type: EXAM_SUCCESS,
        examList: response.data,
      });
    } else {
      dispatch({
        type: EXAM_ERROR,
      });
    }
  };

  const fecthQuestions = async () => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(urlBackEnd + `question`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      dispatch({
        type: LISTQUESTIONS,
        questionList: response.data,
      });
    } else {
      dispatch({
        type: LISTQUESTIONS_ERROR,
      });
    }
  };

  const enrollExam = async (examId) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.post(
      urlBackEnd + 'exam/' + examId + '/attempt/start',
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      localStorage.setItem('location', response.headers.location);
      const urlBackEnd = process.env.REACT_APP_BACKEND_URL_DOMAIN;
      const attempt = await clientAxios.get(
        urlBackEnd + response.headers.location,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (attempt.status === 200) {
        dispatch({
          type: QUESTION_SUCCESS,
          questionList: attempt.data.questions,
          startTime: attempt.data.startedAt,
        });
      } else {
        dispatch({
          type: QUESTION_ERROR,
        });
      }
    }
  };

  const answerQuestion = (attempt, data) => {
    const token = localStorage.getItem('token');
    clientAxios.post(urlBackEnd + `exam/attempt/${attempt}/answer`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const endExam = async (attempt, history) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.put(
      urlBackEnd + `exam/attempt/${attempt}/end`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      const { data } = response;
      history.push(
        '/result/' +
          data.attempt.qualificationStatus +
          '/' +
          data.certificate?._id || '0'
      );
    }
  };

  const updateCourseStatus = (id) => {
    const token = localStorage.getItem('token');
    clientAxios.patch(urlBackEnd + `course/${id}/complete`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const coursePayment = async (invoiceId, couponCode) => {
    try {
      const token = localStorage.getItem('token');
      const response = await clientAxios.post(
        urlBackEnd + `invoice/${invoiceId}/payment`,
        { couponCode: couponCode },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (
        response.status === 201 ||
        response.status === 200 ||
        response.status === 500
      ) {
        dispatch({
          type: PAYMENT_SUCCESS,
          paid: true,
        });
      } else {
        dispatch({
          type: PAYMENT_ERROR,
          paid: false,
        });
      }
    } catch (error) {
      dispatch({
        type: PAYMENT_ERROR,
        paid: false,
      });
    }
  };

  const getInvoiceList = async () => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(urlBackEnd + `invoice`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      dispatch({
        type: INVOICE_SUCCESS,
        invoiceList: response.data,
      });
    } else {
      dispatch({
        type: INVOICE_ERROR,
      });
    }
  };

  const getCertificate = async (id) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(urlBackEnd + `certificate/` + id, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      dispatch({
        type: CERTIFICATE_SUCCESS,
        certificate: response.data,
      });
    } else {
      dispatch({
        type: CERTIFICATE_ERROR,
      });
    }
  };

  const getAttemptByExamId = async (id) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.get(urlBackEnd + `exam/${id}/attempt`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      dispatch({
        type: ATTEMPT_SUCCESS,
        attemptUser: response.data,
      });
    } else {
      dispatch({
        type: ATTEMPT_ERROR,
      });
    }
  };

  const updateUser = async (data) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.put(
      urlBackEnd + 'user/' + data._id,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response);
  };

  const initiatePayment = async (invoiceId) => {
    const token = localStorage.getItem('token');
    const response = await clientAxios.post(
      urlBackEnd + `invoice/${invoiceId}/payment`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      const pymentId = response.headers.location.split('/');
      const attempt = await clientAxios.get(
        urlBackEnd + `payment/${pymentId[4]}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (attempt.status === 200) {
        dispatch({
          type: STRIPE_DETAILS_SUCCESS,
          stripe: attempt.data,
        });
      } else {
        dispatch({
          type: STRIPE_DETAILS_ERROR,
          stripe: null,
        });
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        authenticated: state.authenticated,
        registered: state.registered,
        verified: state.verified,
        email: state.email,
        code: state.code,
        message: state.message,
        updated: state.updated,
        passwordSet: state.passwordSet,
        addressSet: state.addressSet,
        user: state.user,
        courseList: state.courseList,
        moduleLectureList: state.moduleLectureList,
        examList: state.examList,
        questionList: state.questionList,
        startTime: state.startTime,
        enrollDetails: state.enrollDetails,
        invoiceList: state.invoiceList,
        paid: state.paid,
        certificate: state.certificate,
        userDetails: state.userDetails,
        attemptUser: state.attemptUser,
        stripesData: state.stripesData,
        registerUser,
        loginUser,
        fetchUser,
        paymentRequest,
        verifyCode,
        resendEmail,
        updateRegister,
        logOut,
        setPass,
        setAddress,
        sendExamSchedule,
        fetchCourse,
        enrollCourse,
        fetchModule,
        updateProgress,
        fetchExams,
        fecthQuestions,
        enrollExam,
        answerQuestion,
        endExam,
        updateCourseStatus,
        getInvoiceList,
        coursePayment,
        getCertificate,
        getAttemptByExamId,
        updateUser,
        initiatePayment,
        forgotPassword,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
