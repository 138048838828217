import React, { useState, useEffect, useRef, useContext } from 'react';
import AuthContext from '../../context/auth/authContext';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Paper, styled, Typography } from '@mui/material';
import phone from '../../assets/img/course-phone-img.png';
import './Courses.scss';
import security from '../../assets/img/security4.jpg';
import Navbar from '../../components/Elements/Navbar/Navbar';
import PopularCourses from '../PopularCourses/PopularCourses';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Footer from '../../components/Elements/Footer/Footer';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#EBEFFA',
    ...theme.typography.body2,
    paddingLeft: theme.spacing(12),
    paddingBottom: theme.spacing(20),
    paddingTop: theme.spacing(0),
    textAlign: 'left',
    boxShadow: 'none'
}));

function Courses({ history }) {
    const authContext = useContext(AuthContext);
    const { fetchUser, user, enrollCourse } = authContext;
    const [api, setApi] = useState(false);
    const [enroll, setEnroll] = useState(false);

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        if (user) {
            if (user.enrollment.length) {
                const status = user.enrollment.filter((e) => e.status === 'active');
                if (status.length) {
                    setEnroll(true);
                }
            }
            setApi(true);
        }
    }, [user]);
    return (
        <Box className='courses__box'>
            <Navbar
                history={history}
                course={true}
                faq={true}
            />
            <Grid container spacing={2} className="courses__header">
                <Grid xs={12} sm={12} md={6} lg={6} className='desc__box'>
                    <h1>Explore our <br /> amazing course just<br /> for you</h1>
                    <p>Find various courses ranging from Security to <br /> Police to Fire Brigade. All in one place!</p>

                </Grid>
                <Grid xs={12} md={6} lg={6} className='img__box'>
                    <img src={phone} className='phone1'></img>
                </Grid>
            </Grid>
            {user && user !== null && (
                <Grid xs={12} className='my__courses'>
                    <Grid item xs={12} className='title'>
                        <h1 style={{ color: '#000', marginBottom: '3pc' }}>My courses</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="card-stack">
                            <Card sx={{ maxWidth: 345 }} className="card">
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={security}
                                    title="security"
                                    className='cardMedia'
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" className='card__title'>
                                        Certified Security Guard (CSG)
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='card__description'>
                                        Learn the key ideas behind becoming a certified Security Guard, with this disruptive online micro-learning course – no previous experience required!
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ padding: '0px', marginTop: '15px' }}>
                                    <Button onClick={() => history.push('/course/details/view')} className='goto__course__btn'>Go to course <ArrowForwardIcon fontSize='small' style={{ marginLeft: '10px' }} /></Button>
                                </CardActions>
                            </Card>
                        </div>
                    </Grid>
                </Grid>
            )}
            <PopularCourses />
            <Footer />
        </Box>



    )
}

export default Courses