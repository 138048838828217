import React from 'react';
import PropTypes from 'prop-types';
import DynamicInput from '../../Fields/DynamicInput/DynamicInput';
import Loader from '../../Fields/Loader/Loader';

const LoginForm = ({
  handleChange,
  email,
  password,
  handleSubmit,
  classVal,
  formStep,
  loader,
}) => (
  <form className="p-4 flexDirectionCol flex h-full" onSubmit={handleSubmit}>
    <p className="text-xs font-medium w-full reg-label mb-1">PERSONAL EMAIL</p>
    <DynamicInput
      type="text"
      placeholder="email@domain.com"
      handleChange={handleChange}
      value={email || ''}
      name="email"
      customStyle={classVal}
    />
    <p className="text-xs font-medium w-full reg-label mb-1">PASSWORD</p>
    <DynamicInput
      type="password"
      placeholder="Enter your password"
      handleChange={handleChange}
      value={password || ''}
      name="password"
      customStyle={classVal}
    />
    <p className="text-xs w-full text-right py-2" style={{ marginTop: -20 }}>
      <a href="/ForgotPassword" className="inline-flex text-xs reg-label">
        forgot password?
      </a>
    </p>
    <button
      className="btn-fl hover:btn-hv text-white font-medium py-2 p-16 h-10 rounded-full"
      type="submit"
      disabled={!email || !password}
    >
      {loader ? <Loader /> : 'Sign In'}
    </button>
  </form>
);

LoginForm.propTypes = {
  handleChange: PropTypes.func,
  email: PropTypes.string,
  password: PropTypes.string,
  handleSubmit: PropTypes.func,
  formStep: PropTypes.number,
};

export default LoginForm;
