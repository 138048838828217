import React from 'react';
import PropTypes from 'prop-types';
import Row from '../../Elements/Row/Row';

const DinamicInput = ({
  type,
  placeholder,
  customStyle,
  handleChange,
  value,
  name,
  disabled,
}) => (
  <Row>
    <input
      type={type}
      className="appearance-none placeholder-gray-500 placeholder-opacity-100 border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5 mx-0"
      placeholder={placeholder}
      style={customStyle}
      onChange={handleChange}
      value={value}
      name={name}
      disabled={disabled ? true : false}
    />
  </Row>
);

DinamicInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  customStyle: PropTypes.object,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
};
export default DinamicInput;
