import React, { useState, useEffect, useRef, useContext } from 'react';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Stack, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from '@mui/material';
import { Card, CardContent } from '@material-ui/core';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Paper from '@mui/material/Paper';
import './Home.scss';
import logoWhite from '../../assets/img/logo_white.png';
import person_img from '../../assets/img/person-img.png';
import book from '../../assets/img/book.png';
import coffee from '../../assets/img/coffee.png';
import note from '../../assets/img/note-2.png';
import like from '../../assets/img/like.png';
import iphone from '../../assets/img/Phone11-pro-back (2).png';
import phone from '../../assets/img/Group 1.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Navbar from "../../components/Elements/Navbar/Navbar";
import PopularCourses from '../PopularCourses/PopularCourses';
import BecomeMember from '../BecomeMember/BecomeMember';
import Footer from '../../components/Elements/Footer/Footer';

import PersonIcon from '@mui/icons-material/Person';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SecurityIcon from '@mui/icons-material/Security';


export default function Home({ history }) {

    useEffect(() => {
    const script = document.createElement('script');
    script.src = '//fw-cdn.com/3339155/3118002.js';
    script.setAttribute('chat', 'true');
    script.setAttribute('widgetId', '1dfc7528-34a2-480e-9106-37bc95c0b8d8');
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box className='home__box'>
      <Navbar
        history={history}
        course={true}
        faq={true}
        isHome={true}
      />
      <Grid container spacing={2} className='bar__content'>
        <Grid xs={12} sm={12} md={6} lg={6} className='desc__box'>
          <h1>The Future of <br /> Security Education<br /> is here!</h1>
          <p>From the academy that has no borders & where traditional training has become immaterial. Learn security from the masters in the industry with certified online short courses.</p>
          {!localStorage.getItem('token') && (
              <Button href='/Register' variant="contained" color="error" className='member__btn' size='large'>Become a member</Button>
            )}
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} className='img__box' >
          <img src={phone} className='img phone1'></img>
        </Grid>
      </Grid>

      <Box className='info__box'>
        <Typography variant="h1" className="title">
          Get started
        </Typography>
        <p className="paragraph">Take your career to the next level. Build essential security skill <br /> with ISSAcademy with a curated learning experience.</p>
        <Grid container className="cardContainer">
          <Grid item>
            <Card className="card">
              <CardContent className='cardContent'>
                <div className="cardIcon">
                  <SecurityIcon className='icon'/>
                </div>
                <Typography align='left' variant="subtitle2" className='cardText'>
                  Drive safety and protection in your workplace
                </Typography>
                <Button onClick={() => history.push('/courses')} className='findBtn' size='large' endIcon={<ArrowForwardIcon />}>Find courses</Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid>
            <Card className="card">
              <CardContent className='cardContent'>
                <div className="cardIcon">
                  <LanguageOutlinedIcon className='icon'/>
                </div>
                <Typography align='left' variant="subtitle2" className='cardText'>
                  Discover how security is reshaping our world</Typography>
                <Button onClick={() => history.push('/courses')} className='findBtn' size='large' endIcon={<ArrowForwardIcon />}>Find courses</Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid>
            <Card className="card">
              <CardContent className='cardContent'>
                <div className="cardIcon">
                  <PersonIcon className='icon'/>
                </div>
                <Typography align='left' variant="subtitle2" className='cardText'>
                  Become a strong force that empowers people</Typography>
                <Button onClick={() => history.push('/courses')} className='findBtn' size='large' endIcon={<ArrowForwardIcon />}>Find courses</Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Grid className="main_grid">
        <Grid item xs={4} className='img__grid'>
          <img className='person__img' src={person_img} />
        </Grid>
        <Grid item xs={8} md={6} className='textGrid'>
          <h1 className="title" style={{ color: '#FFF' }}>Unleash the World of</h1> <br />
          <h1 className="title" style={{ color: '#28d79c' }}>Security Industry</h1>
          <p className='paragraph'>ISSAcademy creates the world’s best revolutionary security programs in the industry. Learn from the best teachers on our powerful innovative online based learning platform.</p>

          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon className='listIcon' />
              </ListItemIcon>
              <ListItemText className='listItemText'
                primary="Conflict management"
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon className='listIcon' />
              </ListItemIcon>
              <ListItemText className='listItemText'
                primary="Getting familiar with fire fighting"
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon className='listIcon' />
              </ListItemIcon>
              <ListItemText className='listItemText'
                primary="How to secure a scene"
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItem>
          </List>
          {/* <Stack direction="row" spacing={2} marginTop={3}>
            <Button variant="contained" className='find__more__btn' style={{ background: '#28d79c' }}>
              Find out more
            </Button>
            <Button variant="outlined" endIcon={<SearchIcon fontSize="large" className="search__icon" />} className='search__btn' style={{ borderColor: '#FFF' }}>
              Interested in: Security training
            </Button>
          </Stack> */}
        </Grid>
      </Grid>




      <Grid container className='bottom_box'>
        <Grid xs={12} sm={12} md={7} className='text__grid'>
          <h1 style={{ color: '#000' }}>What is ISSAcademy</h1>
          <p style={{ color: '#000' }}>With decades of security training experience by our expertise, the International Security & Safety Academy (ISSA), is a security school, that is revolutionizing the security training industry. The most up-to date and disruptive online security training program in the world. Accelerate your career and improve you're know how, by learning key security concepts, for a minimal investment of time & money.</p>
        </Grid>
        <Grid xs={12} sm={12} md={5} className='logo__grid'>
          <img className='logoWhite' src={logoWhite} />
        </Grid>
      </Grid>



      <Grid container className='edu__model__grid'>
        <Grid xs={12} sm={12} md={6} className='text__grid'>
          <h1 style={{ color: '#000' }}>ISSAcademy's <br /> Micro learning <br /> Education Model</h1>
        </Grid>
        <Grid container xs={12} sm={12} md={6} className='paper__container'>
          <Grid item>
            <Paper elevation={0} className='grid__paper'>
              <img src={note} />
              <h3>Micro learning model</h3>
              <p>Our online micro learning model offers numerous advantages.</p>
            </Paper>
          </Grid>
          <Grid item>
            <Paper elevation={0} className='grid__paper'>
              <img src={book} />
              <h3>Self paced learning</h3>
              <p>It is more convenient, improves content retention & self paced learning.</p>
            </Paper>
          </Grid>
          <Grid item>
            <Paper elevation={0} className='grid__paper'>
              <img src={coffee} />
              <h3>Fun & Interesting</h3>
              <p>Our methodology of learning is fun, convenient & efficient compared to traditional, often too long boring lessons.</p>
            </Paper>
          </Grid>
          <Grid item>
            <Paper elevation={0} className='grid__paper'>
              <img src={like} />
              <h3>Upskill</h3>
              <p>Take your security know how to the next level.</p>
            </Paper>
          </Grid>
        </Grid>
      </Grid>


      <PopularCourses />



      <Grid container xs={12} className='why__choose'>
        <Grid item xs={12} sm={12} md={6} lg={6} className='text__grid'>
          <h1>Why choose ISSAcademy</h1>
          <p>With decades of security training experience by our expertise, the International Security & Safety Academy (ISSA), is a security school, that is revolutionizing the security training industry.</p>

          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon className='list__icon' />
              </ListItemIcon>
              <ListItemText className='list__item__txt'
                primary="Globally Recognised Certification"
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon className='list__icon' />
              </ListItemIcon>
              <ListItemText className='list__item__txt'
                primary="Anywhere access easy online learning"
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon className='list__icon' />
              </ListItemIcon>
              <ListItemText className='list__item__txt'
                primary="Curriculum curated by experts"
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon className='list__icon' />
              </ListItemIcon>
              <ListItemText className='list__item__txt'
                primary="Minimal investment"
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className='img__grid'>
          <img className='phone__img' src={iphone} />
        </Grid>
      </Grid>

      <BecomeMember />
      <Footer />
    </Box>
  )
}
