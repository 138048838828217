import React from 'react';
import PropTypes from 'prop-types';

const Sidebar = ({
    listActive,
    liObject,
  }) => (
    <ul className="flex-col md:block hidden items-center h-full w-2/12 px-6 pt-64">
      {console.log(listActive)}
      {liObject.map((item, i) => {
        return <li className={`p-2 ${listActive === i ? "title-color": "sub-color"}`} key={i}><a className="flex" href="#">
         {i <= listActive ?  <i class="fa fa-check-circle mr-2"></i> : <i class="fa fa-times-circle mr-2"></i>} 
          {item.name}</a></li>
      })}
  </ul>
  );
  
  Sidebar.propTypes = {
    listActive: PropTypes.number,
    liObject: PropTypes.any
  };
  export default Sidebar;
  