import React, { useState, useContext, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import Title from '../../components/Fields/Title/Title';
import Subtitles from '../../components/Fields/Subtitles/Subtitles';
import Logo from './../../components/Elements/Logo';
import PropTypes from 'prop-types';

import LogoImg from '../../assets/images/sign_up.png';

import AuthContext from '../../context/auth/authContext';
import Footer from '../../components/Fields/Footer/Footer';
import ForgotPasswordForm from '../../components/Forms/ForgotPassword/ForgotPasswordForm';
import AlertToast from '../../components/AlertToast/AlertToast';

export const ForgotPassword = ({ history }) => {
  const authContext = useContext(AuthContext);
  const { forgotPassword } = authContext;
  const [errorMessage, setErrorMessage] = useState();
  const [alertStatus, setAlertStatus] = useState(false);
  const [state, setState] = useState({
    email: '',
  });
  const [loader, setLoader] = useState(false);
  const [successApi, setSuccessApi] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    setState({ ...state, email: email });
    const response = forgotPassword({ email });
    response
      .then((res) => {
        setSuccessApi(true);
        setLoader(false);
        setAlertStatus(true);
        setErrorMessage("Send Successfully !");
      })
      .catch((err) => {
        setAlertStatus(false);
        setErrorMessage(err?.response?.data?.message);
        setLoader(false);
      });
    const timeOut = setTimeout(() => {
      setErrorMessage(null);
      clearTimeout(timeOut);
    }, 2000);
    
  };

  const { email } = state;

  return (
    <>
      <div className="register flex dash-bgcolor w-full h-full fixed">
        <div className="flex-1">
        {errorMessage && (
            <AlertToast errorMessage={errorMessage} alertStatus={alertStatus} />
          )}
          <div className="register--box py-56  md:px-32 text-justified ">
            <div>
              <Logo
                history={history}
                width="250px"
                height="40px"
                background="#f6faff"
                color="#242424"
                viewBox="10 0 199 40"
              />
            </div>
            <Title
              customStyle="text-2xl font-medium p-4 title-color"
              value="Forgot Password? Let's get you a new one"
            />
            <Subtitles
              customStyle="text-xs p-4 sub-color"
              value="Enter your email address and we'll send you a link to reset your password."
            />
            <ForgotPasswordForm
              handleChange={handleInput}
              email={email}
              handleSubmit={handleSubmit}
              loader={loader}
            />
            {successApi && (
              <Subtitles
                customStyle="text-xs text-center p-4 sub-color"
                value="Reset link successfully send to your email address."
              />
            )}
            <div className="text-center items-start">
              <p className="mt-2 text-sm">
                <a
                  className="text-sm cursor-pointer hover:font-extrabold"
                  onClick={() => history.push('/')}
                >
                  Home
                </a>
                {' | '}
                <a
                  className="text-sm cursor-pointer hover:font-extrabold"
                  onClick={() => history.push('/support')}
                >
                  Support
                </a>
              </p>
              {/* <a href="#" onClick={() => setSignInPage(false)} className="text-xs inline-flex">Forgot Password?</a> */}
            </div>

            <Footer />
          </div>
        </div>
        <div className="md:block hidden">
          <img
            className="w-auto h-screen object-cover"
            src={LogoImg}
            alt="Login"
            id="login"
          />
        </div>
      </div>

      <Footer position="absolute" />
    </>
  );
};

ForgotPassword.propTypes = {};

export default withRouter(ForgotPassword);
