import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import address_image from '../../assets/images/address.png';
import AuthContext from '../../context/auth/authContext';
import Layout from '../../components/Elements/Layout/Layout';

export const ExamStatus = ({ match, history }) => {
  const authContext = useContext(AuthContext);
  const [status, setStatus] = useState('failed');
  const [percentage, setPercentage]  = useState({});
  const {
    getCertificate,
    certificate
  } = authContext;


  useEffect(() => {
    setStatus(match.params.status);
    getCertificate(match.params.certificate);
  }, [match]);

  useEffect(() => {
    if (certificate !== null) {
      setPercentage(certificate)
    }
  }, [certificate])

  return (
    <div className="w-full">
      <Layout
        style="register flex dash-bgcolor w-full h-full fixed"
        imageURL={address_image}
        imageSize="250"
        imagePosition="90"
      >
        <div className="flex w-full  px-8">
          <div className="flex-col w-full flex justify-center text-center items-center">
            <h4 className="md:text-2xl text-xl">
              {status !== 'failed' && 'Congratulations, '} Your exam has been
              completed successfully!
            </h4>
            <p className="md:w-1/2 2/2 md:text-md text-sm">
              {status !== 'failed'
                ? `Your Score is ${percentage.percentage ? percentage.percentage.toFixed(2) : 0.00} out of 100`
                : 'You have failed this exam. Please schedule the exam for the next available time slot."'}
            </p>
            {status === 'failed' ? (
              <button
                className="border mt-4 border-gray-500 px-5 py-1 rounded-full text-sm uppercase"
                type="submit"
                onClick={() => history.replace('/course/details')}
              >
                My Course
              </button>
            ) : (
              <button
                className="border mt-4 border-gray-500 px-5 py-1 rounded-full text-sm uppercase"
                type="submit"
                onClick={() =>
                  history.replace('/certificate/' + match.params.certificate)
                }
              >
                Get Certificate
              </button>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default withRouter(ExamStatus);
