import React, { useState, useContext, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import RegisterForm from '../../components/Forms/RegisterForm/RegisterForm';
import Title from '../../components/Fields/Title/Title';
import Subtitles from '../../components/Fields/Subtitles/Subtitles';
import Logo from './../../components/Elements/Logo';
import PropTypes from 'prop-types';

import LogoImg from '../../assets/images/sign_up.png';

import AuthContext from '../../context/auth/authContext';
import Footer from '../../components/Fields/Footer/Footer';

export const Register = ({ register, history }) => {
  const authContext = useContext(AuthContext);
  const { message, registered, registerUser, resendEmail } = authContext;
  const [state, setState] = useState({
    loader: false,
    email: '',
    password: '',
    region: '',
    labelvalue: 'PERSONAL EMAIL',
    passwordlabel: 'PASSWORD',
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === 'region') localStorage.setItem('country', value);
    setState({ ...state, [name]: value });
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === '' || password === '' || region === '') {
      setState({
        ...state,
        labelvalue: 'PERSONAL EMAIL , PASSWORD & REGION IS REQUIRED',
      });
    } else if (!validatePassword(state.password)) {
      setState({
        ...state,
        passwordlabel: 'Password must be at least 8 characters with uppercase, lowercase, number, and special character.',
      });
    } else {
      setState({
        ...state,
        loader: true,
        email: email,
        password: password,
        labelvalue: '',
      });
      registerUser({ email, password, region });
    }
  };

  useEffect(() => {
    if (registered === false) {
      localStorage.setItem('verified', false);
      localStorage.removeItem('auth');
      history.push('/Verification');
      setState({ ...state, loader: false });
    }
    if (registered) {
      localStorage.removeItem('verified');
      localStorage.removeItem('email');
      alert('user already registered');
      setState({ ...state, loader: false });
    }
  }, [message, registered, history]);

  const { email, loader, password, labelvalue, region, passwordlabel } = state;

  return (
    <>
      <div className="register flex dash-bgcolor w-full h-full fixed">
        <div className="flex-1">
          <div className="register--box pt-8 md:px-32 text-justified mx-auto">
            <div>
              <Logo
                history={history}
                width="250px"
                height="40px"
                background="#f6faff"
                color="#242424"
                viewBox="10 0 199 40"
              />
            </div>
            <Title
              customStyle="text-2xl font-medium p-4 title-color"
              value="Sign up to your account to ISS Academy now!"
            />
            <Subtitles
              customStyle="text-xs p-4 sub-color"
              value="Are you in for a new career in a booming industry?. Sign up now for a lucrative career in a private security industry."
            />
            <RegisterForm
              handleChange={handleInput}
              email={email}
              password={password}
              region={region}
              handleSubmit={handleSubmit}
              labelval={labelvalue}
              passwordlabel={passwordlabel}
              loader={loader}
            />
            <div className="text-center items-start">
              <p className="text-xs">
                Already have an account?&nbsp;
                <a href="/Login" className="inline-flex text-xs font-bold">
                  Sign In
                </a>
              </p>
              <p className="mt-2 text-sm">
                <a
                  className="text-sm cursor-pointer hover:font-extrabold"
                  onClick={() => history.push('/')}
                >
                  Home
                </a>
                {' | '}
                <a
                  className="text-sm cursor-pointer hover:font-extrabold"
                  onClick={() => history.push('/support')}
                >
                  Support
                </a>
              </p>
            </div>
            {/* <Footer position="absolute"/> */}
          </div>
        </div>
        <div className="md:block hidden">
          <img
            className="w-auto h-screen object-cover"
            src={LogoImg}
            alt="Register"
            id="register"
          />
        </div>
      </div>
    </>
  );
};

Register.propTypes = {};

export default withRouter(Register);
