export const QUALIFICATION = [
  {
    name: 'High School',
  },
  {
    name: 'Secondary School',
  },
  {
    name: 'Graduate',
  },
  {
    name: 'Postgraduate',
  },
];
