import React from 'react';
import './loader.css';

const Loader = ({ variant, label }) => {
  return (
    <div className="loader">
      <div
        className={`loader__icon ${
          variant === 'dark' ? 'loader__icon--dark' : 'loader__icon--light'
        }`}
      ></div>
      {label ? <div className="loader__text">{label}</div> : undefined}
    </div>
  );
};
export default Loader;
