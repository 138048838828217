export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';

export const USERDETAIL_SUCCESS = 'USERDETAIL_SUCCESS';

export const VENDOR_SUCCEESS = 'VENDOR_SUCCEESS';
export const VENDOR_ERROR = 'VENDOR_ERROR';

export const LOG_OUT = 'LOG_OUT';

export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_ERROR = 'VERIFICATION_ERROR';

export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_ERROR = 'UPDATE_ERROR';

export const PASS_SUCCESS = 'PASS_SUCCESS';
export const PASS_ERROR = 'PASS_ERROR';

export const ADDRESS_SUCCESS = 'ADDRESS_SUCCESS';
export const ADDRESS_ERROR = 'PASS_ERRADDRESS_ERRORR';

export const COURSE_SUCCESS = 'COURSE_SUCCESS';
export const COURSE_ERROR = 'COURSE_ERROR';

export const LECTURE_SUCCESS = 'LECTURE_SUCCESS';
export const LECTURE_ERROR = 'LECTURE_ERROR';

export const EXAM_SUCCESS = 'EXAM_SUCCESS';
export const EXAM_ERROR = 'EXAM_ERROR';

export const QUESTION_SUCCESS = 'QUESTION_SUCCESS';
export const QUESTION_ERROR = 'QUESTION_ERROR';

export const ENROLL_SUCCESS = 'ENROLL_SUCCESS';
export const ENROLL_ERROR = 'ENROLL_ERROR';

export const INVOICE_SUCCESS = 'INVOICE_SUCCESS';
export const INVOICE_ERROR = 'INVOICE_ERROR';

export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';

export const CERTIFICATE_SUCCESS = 'CERTIFICATE_SUCCESS';
export const CERTIFICATE_ERROR = 'CERTIFICATE_ERROR';

export const LISTQUESTIONS = 'LISTQUESTIONS';
export const LISTQUESTIONS_ERROR = 'LISTQUESTIONS_ERROR';

export const ATTEMPT_SUCCESS = 'ATTEMPT_SUCCESS';
export const ATTEMPT_ERROR = 'ATTEMPT_ERROR';

export const STRIPE_DETAILS_SUCCESS = 'STRIPE_DETAILS_SUCCESS';
export const STRIPE_DETAILS_ERROR = 'STRIPE_DETAILS_ERROR';
