import React, { useEffect, useState } from 'react';

const formatTime = (time) =>
  `${String(Math.floor(time / (60 * 60))).padStart(2, '0')}:${String(
    Math.floor(time / 60)
  ).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`;

const Timer = ({ time, totalHours, text, callBack }) => {
  const timeRemain = totalHours - (time % totalHours);
  callBack(timeRemain);
  return (
    <>
      <div>
        {text} {formatTime(timeRemain)}
      </div>
    </>
  );
};

const ExamTimer = ({ totalHours, text, callBack }) => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    const timerId = setInterval(() => {
      setTime((t) => t + 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  return (
    <Timer
      time={time}
      totalHours={totalHours}
      text={text}
      callBack={callBack}
    />
  );
};

export default ExamTimer;
