import {
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOG_OUT,
  VERIFICATION_SUCCESS,
  VERIFICATION_ERROR,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  PASS_SUCCESS,
  PASS_ERROR,
  ADDRESS_SUCCESS,
  ADDRESS_ERROR,
  USER_SUCCESS,
  USER_ERROR,
  COURSE_ERROR,
  COURSE_SUCCESS,
  LECTURE_SUCCESS,
  EXAM_SUCCESS,
  EXAM_ERROR,
  QUESTION_SUCCESS,
  QUESTION_ERROR,
  ENROLL_SUCCESS,
  ENROLL_ERROR,
  INVOICE_SUCCESS,
  INVOICE_ERROR,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  CERTIFICATE_SUCCESS,
  CERTIFICATE_ERROR,
  USERDETAIL_SUCCESS,
  ATTEMPT_SUCCESS,
  ATTEMPT_ERROR,
  STRIPE_DETAILS_SUCCESS,
  STRIPE_DETAILS_ERROR,
} from '../../types';

export default (state, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      localStorage.setItem('email', action.payload);
      return {
        ...state,
        registered: action.verified,
      };

    case REGISTER_ERROR:
      return {
        ...state,
        registered: action.registered,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload);
      return {
        ...state,
        authenticated: true,
        message: null,
        user: action.user,
      };

    case LOGIN_ERROR:
      localStorage.removeItem('token');
      localStorage.removeItem('auth');
      return {
        ...state,
        message: action.payload,
      };
    case USER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case USER_ERROR:
      return {
        ...state,
        user: null,
      };

    case LOG_OUT:
      localStorage.removeItem('token');
      localStorage.removeItem('auth');
      localStorage.removeItem('verified');
      return {
        ...state,
        authenticated: null,
        user: null,
      };

    case VERIFICATION_SUCCESS:
      return {
        ...state,
        verified: true,
        message: null,
      };

    case VERIFICATION_ERROR:
      return {
        ...state,
        message: action.payload,
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        updated: true,
        message: null,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        updated: null,
        message: action.payload,
      };

    case PASS_SUCCESS:
      return {
        ...state,
        passwordSet: true,
        message: null,
      };
    case PASS_ERROR:
      return {
        ...state,
        passwordSet: null,
        message: action.payload,
      };
    case ADDRESS_SUCCESS:
      return {
        ...state,
        addressSet: true,
        message: null,
      };
    case ADDRESS_ERROR:
      return {
        ...state,
        addressSet: null,
        message: action.payload,
      };
    case COURSE_SUCCESS:
      return {
        ...state,
        courseList: action.courseList,
      };
    case COURSE_ERROR:
      return {
        ...state,
        courseList: [],
      };
    case LECTURE_SUCCESS:
      const mergeArray = action.moduleList.map((t1) => ({
        ...t1,
        ...action.lectureList.find((t2) => t2.module === t1._id),
      }));
      return {
        ...state,
        moduleLectureList: mergeArray,
      };
    case EXAM_SUCCESS:
      return {
        ...state,
        examList: action.examList,
      };
    case EXAM_ERROR:
      return {
        ...state,
        examList: [],
      };
    case QUESTION_SUCCESS:
      return {
        ...state,
        questionList: action.questionList,
        startTime: action.startTime,
      };
    case QUESTION_ERROR:
      return {
        ...state,
        questionList: [],
      };
    case ENROLL_SUCCESS:
      return {
        ...state,
        enrollDetails: action.enrollDetails,
      };
    case ENROLL_ERROR:
      return {
        ...state,
        enrollDetails: null,
      };
    case INVOICE_SUCCESS:
      return {
        ...state,
        invoiceList: action.invoiceList,
      };
    case INVOICE_ERROR:
      return {
        ...state,
        invoiceList: [],
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        paid: action.paid,
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        paid: action.paid,
      };
    case CERTIFICATE_SUCCESS:
      return {
        ...state,
        certificate: action.certificate,
      };
    case CERTIFICATE_ERROR:
      return {
        ...state,
        certificate: null,
      };
    case USERDETAIL_SUCCESS:
      return {
        ...state,
        userDetails: state.userDetails,
      };
    case ATTEMPT_SUCCESS:
      return {
        ...state,
        attemptUser: action.attemptUser,
      };
    case ATTEMPT_ERROR:
      return {
        ...state,
        attemptUser: [],
      };
    case STRIPE_DETAILS_SUCCESS:
      return {
        ...state,
        stripesData: action.stripe,
      };
    case STRIPE_DETAILS_ERROR:
      return {
        ...state,
        stripesData: null,
      };
    default:
      return state;
  }
};
