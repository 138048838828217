import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  ProtectedRoute,
  ProtectedRouteRegister,
} from './routes/protected.route';

import { Login } from './pages/Login/Login';
import { Register } from './pages/Register/Register';
import { VerificationCode } from './pages/Register/VerificationCode/VericationCode';
import { Account } from './pages/Register/Account/Account';
import { PersonalInfo } from './pages/Register/Personalnfo/PersonalInfo';
import { Address } from './pages/Register/Address/Address';
import { Identity } from './pages/Register/Identity/Identity';
import { Legal } from './pages/Register/Legal/Legal';
import { Confirm } from './pages/Confirm/Confirm';
import { Course } from './pages/Course/Course';
import Support from './pages/Support/Support';
import CourseView from './pages/Course/CourseView';

import AuthState from './context/auth/authState';
// import Footer from './components/Fields/Footer/Footer';
import Landing from './pages/Landing/index';
import CourseDetails from './pages/Course/Details';
import ExamQuestion from './pages/Exam/Exam';
import { ExamStatus } from './pages/ExamStatus/ExamStatus';
import Certificate from './pages/Certificate/Certificate';
import Payment from './pages/Payment/Payment';
import Confirmation from './pages/Payment/Confirm';
import { UserProfile } from './pages/Profile/UserProfile';
import PaymentWrapper from './pages/Payment/PaymentWrapper';
import { ForgotPassword } from './pages/ForgotPassword/Index';
import { createTheme, ThemeProvider } from '@mui/material';
import Home from './pages/Home/Home';
import Faq from './pages/Faq/Faq';
import AboutUs from './pages/AboutUs/AboutUs';
import Courses from './pages/Courses/Courses';
import ScrollToTop from './components/ScrollToTop';
import WhyISSAcademy from './pages/WhyISSAcademy/WhyISSAcademy';
import ContactUs from './pages/ContactUs/ContactUs';
import Blog from './pages/Blog/Blog';
import Footer from './components/Elements/Footer/Footer';
import NewPayment from './pages/NewPayment/NewPayment';

const App = (props) => {

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Nunito Sans',
        'sans-serif',
      ].join(','),
    },
  });

  return (
    <ThemeProvider theme={theme}>
    <div className="main-container">
      <AuthState>
        <Router>
          {/* <Route exact path="/" component={Register} /> */}
          <ScrollToTop />
          <Route exact path="/Register" component={Register} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/ForgotPassword" component={ForgotPassword} />
          <Route exact path="/Verification" component={VerificationCode} />
          <Route exact path="/CourseView" component={Course} />
          <ProtectedRouteRegister exact path="/Account" component={Account} />
          <ProtectedRouteRegister
            exact
            path="/PersonalInfo"
            component={PersonalInfo}
          />
          <ProtectedRouteRegister exact path="/Address" component={Address} />
          <ProtectedRouteRegister exact path="/Identity" component={Identity} />
          <ProtectedRouteRegister exact path="/Legal" component={Legal} />
          <Route exact path="/Confirm" component={Confirm} />
          <ProtectedRouteRegister
            exact
            path="/Course/:id/lecture"
            component={Course}
          />
          {/* <Route exact path="/" component={Landing} /> */}
          <Route exact path="/Support" component={Faq} />
          <ProtectedRouteRegister
            exact
            path="/exam/:id/:course"
            component={ExamQuestion}
          />
          <Route exact path="/course/details/view" component={CourseView} />
          <Route exact path="/course/details" component={CourseDetails} />
          <ProtectedRouteRegister
            exact
            path="/result/:status/:certificate"
            component={ExamStatus}
          />
          <ProtectedRouteRegister
            exact
            path="/certificate/:id"
            component={Certificate}
          />
          <Route exact path="/course/:id/payment" component={PaymentWrapper} />
          <ProtectedRoute
            exact
            path="/enroll/confirm/:id"
            component={Confirmation}
          />
          <ProtectedRoute exact path="/user/profile/" component={UserProfile} />


          <Route exact path="/" component={Home} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/courses" component={Courses} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/whyissa" component={WhyISSAcademy} />
          <Route exact path="/contactus" component={ContactUs} />
        </Router>
      </AuthState>
    </div>
    </ThemeProvider>
  );
};

export default App;
