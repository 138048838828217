import React from 'react';
import PropTypes from 'prop-types';

const Subtitles = ({
  customStyle,
  value,
}) => (
  <p className={customStyle}>{value}</p>
);
Subtitles.propTypes = {
  customStyle: PropTypes.string,
  value: PropTypes.string,
};

export default Subtitles;
