import React from 'react';
import PropTypes from 'prop-types';
import DynamicInput from '../../../Fields/DynamicInput/DynamicInput';
import Select from '../../../Fields/Select/Select';
import Subtitles from '../../../Fields/Subtitles/Subtitles';
import ButtonDynamic from '../../../Fields/Button/Button';
import qualifications from './mock';
import List from '../../../Elements/List/List';

const PersonalForm = ({
  handleChange,
  handleSubmit,
  classVal,
  firstName,
  lastName,
  qualification,
  month,
  day,
  year,
  btnOnclick,
  toogleBtnList,
}) => {
  const qualificationList = qualifications();

  return (
    <form
      className="flexDirectionCol flex mx-64 px-16 mt-8 register--box"
      onSubmit={handleSubmit}
    >
      <p className="text-xs font-medium w-full reg-label mb-1 text-left">
        FIRST NAME *
      </p>
      <DynamicInput
        type="text"
        placeholder=""
        handleChange={handleChange}
        value={firstName || ''}
        name="firstName"
        customStyle={classVal}
      />
      <p className="text-xs font-medium w-full reg-label mb-1 text-left">
        LAST NAME *
      </p>
      <DynamicInput
        type="text"
        placeholder=""
        handleChange={handleChange}
        value={lastName || ''}
        name="lastName"
        customStyle={classVal}
      />
      <p className="text-xs font-medium w-full reg-label mb-1 text-left">
        QUALIFICATION *
      </p>
      {/*<ButtonDynamic
        btnType="button"
        btnText="Select One"
        customStyle="bg-white border rounded-full w-full py-2 title-color focus:outline-none mb-5 mx-0"
        clickHandler={btnOnclick}
      />

      <div className="fixed ">
        {toogleBtnList ? (
          <List
            list={qualificationList}
            customStyle="flex flex-col text-left bg-white"
            value={qualification}
            name="qualification"
          />
        ) : null}
        </div>*/}

      <select
        name="qualification"
        id="qualification"
        onChange={handleChange}
        value={qualification}
        className="form-select placeholder-gray-500 placeholder-opacity-100 border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5 mx-0"
      >
        <option value="Certified Security Guard">
          Certified Security Guard
        </option>
        <option value="Security Supervisor Course">
          Security Supervisor Course
        </option>
        <option value="Security Manager Course">Security Manager Course</option>
      </select>
      <p className="text-xs mb-4 font-medium w-full reg-label mb-1 text-left">
        Date of birth *
      </p>
      <div className="flex">
        <div className="flexDirectionCol mr-4">
          <p className="text-xs font-medium w-full reg-label mb-1 text-left">
            MONTH
          </p>
          <DynamicInput
            type="number"
            placeholder=""
            handleChange={handleChange}
            value={month || ''}
            name="month"
            customStyle={classVal}
          />
        </div>
        <div className="flexDirectionCol ml-4">
          <p className="text-xs font-medium w-full reg-label mb-1 text-left">
            DAY
          </p>
          <DynamicInput
            type="number"
            placeholder=""
            handleChange={handleChange}
            value={day || ''}
            name="day"
            customStyle={classVal}
          />
        </div>
        <div className="flexDirectionCol ml-4">
          <p className="text-xs font-medium w-full reg-label mb-1 text-left">
            YEAR
          </p>
          <DynamicInput
            type="number"
            placeholder=""
            handleChange={handleChange}
            value={year || ''}
            name="year"
            customStyle={classVal}
          />
        </div>
      </div>
      <button
        className="btn-fl hover:btn-hv text-white font-medium py-2 p-24 rounded-full"
        type="submit"
        disabled={!firstName || !lastName || !month || !day || !year}
      >
        Get Started
      </button>
      <div className="flex py-4">
        <Subtitles
          customStyle="text-xs pl-4 sub-color"
          value="or press ENTER↵"
        />
      </div>
    </form>
  );
};

PersonalForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  classVal: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  qualification: PropTypes.string,
  month: PropTypes.string,
  day: PropTypes.string,
  year: PropTypes.string,
  btnOnclick: PropTypes.func,
  toogleBtnList: PropTypes.bool,
};

export default PersonalForm;
